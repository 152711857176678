<template>
  <div class="page-container h-screen  text-black">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent text-white "
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full ">
      <div class="overflow-y-auto h-full">
        <div class="text-center mt-4">
<!--          <p class=" text-sm py-2 rounded w-auto " style="background: #DCDCDC;color: #CF0010;">提现如果遇到高峰期，可能会延迟到账</p>-->
          <h1 class=" font-bold mt-4" style=" color: #CF0010;font-size:40px">{{ balance }}</h1>
          <p style="font-size: 20px;margin-bottom: 20px">当前股权数量(股)</p>
        </div>

        <div class="bg-white rounded-t-lg p-4 mt-6 w-full">
          <h2 class="text-black text-lg font-bold mb-4">股权 • 出售</h2>
          <!-- 固定金额选择 -->
          <div class="mb-4">
            <div class="grid grid-cols-3 gap-2">
              <Button
                  v-for="(amount,index) in withdrawQuotaList"
                  :key="amount.id"
                  :type="selected === index ? 'danger' : 'default'"
                  class="w-full"
                  @click="selectAmount(index)"
              >
                {{ amount.name }}
              </Button>
            </div>
          </div>

          <!-- 当前选择金额 -->
          <div class="text-lg text-gray-600 mb-4 font-bold">
            当前选择 <span class="text-red-500 ">{{ selectedAmount.name }}</span>
          </div>

          <Button type="primary" round block color="#CF0010" @click="onSubmit" class="w-full "
                  style="margin-bottom: 36px;font-size: 18px">
            立即出售
          </Button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";


export default {
  components: {NavBar, Button,},
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title;
    },
  },
  data() {
    return {
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
      withdrawQuotaList: [],
      selected:0,
    }
  },

  created() {
    this.getWithdrawQuotaList();
  },

  methods: {
    ...mapActions("user", ["getUserWallet"]),

    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.go(-1);
    },
    formatAmount(amount) {
      if(amount&&amount.num){
        return `${amount.num}股`;
      }
      return ""
    },
    selectAmount(index) {
      this.selected = index;
      this.selectedAmount = this.withdrawQuotaList[index];
    },
    getWithdrawQuotaList() {
      this.withdrawQuotaList = [
          {
            "id": 31,
            "coin_id": 4,
            "name": "6000股/10万",
            "name_en": "stock",
            "num": "6000.00",
            "price": "300.00",
            "status": 1,
            "created_at": "2024-07-07 15:56:53",
            "updated_at": "2024-09-04 12:44:59",
            "get_num": "0"
          },
          {
            "id": 32,
            "coin_id": 4,
            "name": "15000/30万",
            "name_en": "stock",
            "num": "15000.00",
            "price": "600.00",
            "status": 1,
            "created_at": "2024-07-07 15:56:53",
            "updated_at": "2024-09-04 12:47:10",
            "get_num": "0"
          },
          {
            "id": 33,
            "coin_id": 4,
            "name": "20000/80万",
            "name_en": "stock",
            "num": "20000.00",
            "price": "1200.00",
            "status": 1,
            "created_at": "2024-07-07 15:56:53",
            "updated_at": "2024-09-04 12:47:17",
            "get_num": "0"
          },
          {
            "id": 34,
            "coin_id": 4,
            "name": "35000/180万",
            "name_en": "stock",
            "num": "35000.00",
            "price": "2000.00",
            "status": 1,
            "created_at": "2024-07-07 15:56:53",
            "updated_at": "2024-09-04 12:47:19",
            "get_num": "0"
          },
          {
            "id": 35,
            "coin_id": 4,
            "name": "80000/380万",
            "name_en": "stock",
            "num": "80000.00",
            "price": "3000.00",
            "status": 1,
            "created_at": "2024-07-07 15:56:53",
            "updated_at": "2024-09-04 12:47:29",
            "get_num": "0"
          }
        ]
      this.selectedAmount = this.withdrawQuotaList[0];
      // axios.get(api.getWithdrawQuotaList, {params: {coin_name: this.data.type}}).then((res) => {
      //   this.withdrawQuotaList = res.data;
      //   this.selectedAmount = res.data[0];
      // }).catch((err) => {
      //   console.log(err);
      // });
    },
    onSubmit() {
      const {selectedAmount} = this;
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      axios.post(api.userWithdrawal, {
        num: selectedAmount.num,
        coin_type: this.data.type,
      }).then((res) => {
        this.$toast.clear();
        this.$toast("售出成功");
        this.getUserWallet();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        this.$dialog.alert({
          title: '提示',
          message: err.msg || '售出失败',
        })
            .then(() => {
              if (err.code == 202) {
                this.$router.push({
                  name: "paySXFee",
                  query:
                      {
                        title: '转让费',
                        source: 3,
                        project_id: 5,
                        amount: err.data.price
                      }
                })
              }
            })
            .catch(() => {
            });
      });
    },
  },


}
</script>

<style scoped lang="less">
.page-container {
  background-image: url("../../assets/img/index/bg_top3.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

}

.item-box {
  margin-top: 40px;
  margin-bottom: 36px;

  .item {
    height: 55px;
    margin-top: 22px;
    display: flex;
    border: grey solid 1px;
    border-radius: 10px;
    align-items: center;
    padding: 0px 22px;

    span {
      width: 8%;
      background: none;
      font-size: 30px;
      font-weight: bold;
    }


    input {
      width: 97%;
      padding-left: 3%;
      height: 100%;
      background: none;
      font-size: 20px;
      font-weight: bold;
      color: #333;

    }

    input::placeholder {
      color: rgba(128, 128, 128, 0.5);
    }
  }
}


</style>
