<template>
  <div class="h-full w-full bg-top">
    <div class="overflow-hidden w-full">
      <div class="page-content-2 pt-4">
        <div class="index_box ml-2">
          <img style="height: 40px" class=" " src="@/assets/img/index/ic_header.png">
          <span class="text-white text-xl ">习近平与一带一路</span>
        </div>
        <Banner></Banner>
<!--        <img class="mt-4" src="@/assets/img/index/ic_video.png">-->
        <HomeMenu></HomeMenu>
        <News></News>
      </div>
    </div>
    <popup class="bg-transparent" style="max-width: 80%;overflow-x: hidden;" v-model="dialog" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice_big.png" alt="" style="width: 25px;height: 25px;margin-right: 2px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="problem" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(1)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
    <popup class="bg-transparent" style="max-width: 80%;overflow-x: hidden;" v-model="dialog2" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice_big.png" alt="" style="width: 25px;height: 25px;margin-right: 2px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="introduce" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(2)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import Banner from "@/components/home/Banner";
import HomeMenu from "@/components/home/HomeMenu";
import {mapActions, mapState} from "vuex";
import {isNullOrEmpty} from "@/utils";
import {Popup,} from "vant";
import News from "@/components/home/News";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "index",
  components: {Banner, HomeMenu,News, Popup},
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      console.log('title', this.$route.meta.title)
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      problem: null,
      introduce: null,
      newsItems: [
        {id: 1, image: require('@/assets/img/index/ic_news.png'), text: '也是中国目前事实上最大的线上宅文化社区，还是中'},
        {id: 2, image: require('@/assets/img/index/ic_news.png'), text: '也是中国目前事实上最大的线上宅文化社区，还是中'},
        {id: 3, image: require('@/assets/img/index/ic_news.png'), text: '也是中国目前事实上最大的线上宅文化社区，还是中'},
        {id: 4, image: require('@/assets/img/index/ic_news.png'), text: '也是中国目前事实上最大的线上宅文化社区，还是中'},
      ],
    };
  },
  created() {
    // 从本地存储中获取 this.problem 的值
    const savedProblem = sessionStorage.getItem('problem');
    if (savedProblem) {
      this.problem = savedProblem;
    }
  },
  beforeDestroy() {
    // 在组件销毁之前将 this.problem 的值保存到本地存储
    sessionStorage.setItem('problem', this.problem);
  },
  watch: {
    systemInfo(newVal) {
      if (!isNullOrEmpty(newVal.problem)) {
        this.dialog = true;
        this.problem = newVal.problem;
      }
      if (!isNullOrEmpty(newVal.introduce)) {
        this.introduce = newVal.introduce;
      }
    }
  },
  mounted() {
    console.log("首页mounted");
    this.getUserInfo();
    this.getNoticeList();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    closeDialog(type) {
      if (type === 1) {
        this.dialog = false;
        if (!isNullOrEmpty(this.introduce)) {
          // setTimeout(() => {
          this.dialog2 = true;
          // }, 1000);
        }
      } else {
        this.dialog2 = false;
      }
    },
    getNoticeList() {
      axios
          .get(api.newsList,{params: {type: 1,index_status:1}})
          .then((res) => {
            console.log('公告列表',res)
            if(res.data.length>0){
              this.problem = res.data[0].content;
              if(res.data.length>1){
                this.introduce = res.data[1].content;
              }
              if(res.data.length>2){
                this.home_show = res.data[2].content;
              }
              this.dialog = true;
            }
          })
          .catch(() => {

          });
    },
  },

};
</script>

<style scoped lang="less">
.index_box {
  margin-top: 28px;
  margin-bottom: 20px;
}

.bg-top {
  background-image: url("../../assets/img/index/bg_top.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.bottom {
  background-image: linear-gradient(113deg, #4B49FF 0%, #7D9AFF 100%);
  border-radius: 4px;
}

.notice-title {
  width: 320px;
  height: 67px;
}


.company_news {
  font-size: 16px;
  margin-top: 20px;


  .news_text {
    font-weight: bold;
  }

  .Latest_News {
    margin-bottom: 4px;
    font-size: 12px;
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

}
</style>
