<template>
  <div v-if="prizes_record.length>0" class="bg-white rounded-lg p-4 mb-4">
    <div class="flex flex-row items-center mb-4">
      <img src="@/assets/img/lottery/gift.png" style="height: 20px" class="mr-1"/>
      <h2 class="text-lg font-bold ">我的奖品</h2>
    </div>

    <div class="space-y-3">
      <div v-for="(prize, index) in prizes_record" :key="index" class="border border-gray-200 rounded-lg p-2">
        <div class="flex items-center space-x-2">
          <div class="rounded bg-D4D4D4">
            <img :src="prizeImage(prize.type)" alt="Prize" class="w-16 h-16 object-cover rounded-md">
          </div>
          <div class="flex-1 relative">
            <div class="flex items-center justify-between">
              <p class="font-bold">{{ prize| formatPrizeName }}</p>
              <div
                  class="absolute right-0 top-0 px-2 py-1 rounded-full text-sm "
                  :class="classObject(prize.status)"
                  v-html="getFormatLabel(prize.status)"
                  @click="jumpToPage(prize)"
              >
                {{ getFormatLabel(prize.status)}}
              </div>
            </div>
            <p class="text-sm text-black">领取需求: 邀请实名注册{{prize.need_invite_num}}人</p>
            <p class="text-sm text-black">当日邀请: <span class="text-EE2606">{{ prize.todayInviteCount }}人</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";

export default {
  data() {
    return {
      prizes_record: [],
    };
  },

  mounted() {
    this.loadData();
  },

  filters: {
    formatPrizeName(value) {
      if (value.type == 1) {
        return `${value.num}扶持金`
      } else {
        return `黄金${value.num}克`
      }
    },
  },
  methods: {
    loadData() {
      axios
          .get(api.drawRecord)
          .then((res) => {
            this.prizes_record=res.data.list||[]
          })
          .catch((err) => {
          });
    },
    jumpToWithdraw(data, title) {

      // if (data.name_en == 'wuxing_coin'&&this.eCnyBalance<=0){
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '要提现五行币需要先购买数字人民币，请先购买数字人民币',
      //     theme: 'round-button',
      // }).then(() => {
      //     this.$bus.$emit('active', 2);
      //     this.$router.push({
      //       name: "fund",
      //
      //     });
      //   });
      //   return
      // }
      // if (data.name_en == 'e_cny'&&this.stockBalance<=0){
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '要提现数字人民币需要先购买五行股权，请先购买五行股权',
      //     theme: 'round-button',
      // }).then(() => {
      //     this.$bus.$emit('active', 3);
      //     this.$router.push({
      //       name: "debt",
      //
      //     });
      //   });
      //   return
      // }

      if (data.name_en == 'wuxing_coin' || data.name_en == 'e_cny' || data.name_en == 'stock') {
      //   if (data.name_en == 'wuxing_coin'){
      //     console.log('点击提现五行币')
      //     this.$dialog.alert({
      //       title: '提示',
      //       message: '8月1号建军节正式开网开放提现五行币，请提前准备好您的五行币。',
      //       theme: 'round-button',
      //     }).then(() => {
      //
      //     });
      //   }else
          {
          this.$dialog.alert({
          title: '提示',
          message: '等待官方通知',
          theme: 'round-button',
        }).then(() => {

        });}

        return

      }

      console.log('点击提现',data)
      this.$router.push({
        name: "withdrawal",
        query: {...data,title},
      });
    },
    prizeImage(value){
      if (value == 1) {
        return require('@/assets/img/lottery/fpj.png')
      }else{
        return require('@/assets/img/lottery/prize7.png')
      }
    },
    getFormatLabel(status) {
      switch (status) {
        case 2:
          return '立即邀请'
        case 1:
          return "&nbsp;&nbsp;已领取&nbsp;&nbsp;"
        default:
          return "立即领取"
      }
    },
    classObject(status) {
      switch (status) {
        case 2:
          return 'btn-receipt';
        case 1:
          return 'btn-complete';
        default:
          return 'btn-normal';
      }
    },
    jumpToPage(item) {
      switch (item.status) {
        case 0:
          this.receiveAward(item.id)
          break;
        case 1:
          this.$toast('该奖项已被领取');
          break;
        default:
          this.$router.push({name: 'inviteUser'})
          break;
      }
    },
    receiveAward(id) {
      axios
          .get(api.getAward, {params: {id}})
          .then((res) => {
            this.$toast("领取成功");
            this.loadMore();
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });

    },
  },
};
</script>

<style lang="less" scoped>
.btn-normal {
  background: linear-gradient(90deg, #FF5455, #FFB533);
  color: white;
}

.btn-complete {
  background: #8B7073;
  color: white;
}

.btn-receipt {
  background: #AB0F13;
  color: white;
}
</style>
