<template>
  <div class="page-container h-screen text-white">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent text-white "
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full ">
      <div class="overflow-y-auto h-full">
        <div class="text-center mt-4">
          <p class=" text-sm py-2 rounded w-auto" style="background: #B72026;color: rgba(255,255,255,0.5);">
            立即邮寄一般3-7个工作日邮寄到你填写的地址哦！</p>
          <h1 class=" font-bold mt-4" style="font-size:30px">{{ balance }}g</h1>
          <p style="font-size: 20px;margin-bottom: 40px">黄金重量</p>
        </div>

        <div  class="bg-white rounded-t-lg p-4 mt-6 w-full">
          <h2 class="text-black text-lg font-bold mb-4">{{ title }}</h2>
          <!-- 固定金额选择 -->
          <div class="mb-4">
            <div class="grid grid-cols-3 gap-2">
              <Button
                  v-for="(amount,index) in withdrawQuotaList"
                  :key="amount.id"
                  :type="selected === index ? 'danger' : 'default'"
                  class="w-full"
                  @click="selectAmount(index)"
              >
                {{ formatAmount(amount) }}
              </Button>
            </div>
          </div>

          <!-- 当前选择金额 -->
          <div class="text-sm text-gray-600 mb-4">
            当前选择 <span class="text-red-500 font-bold">{{ formatAmount(selectedAmount) }}</span>
          </div>
          <Button round block type="info" color="#e32429"  class="w-full " @click.stop="onSubmit"
                  style="margin-bottom: 36px;font-size: 18px">
            立即邮寄
          </Button>
          <div @click="jumpToRecord" class="text-lg text-gray-500 block mt-2 text-center"
             style="text-decoration: underline"
          >邮寄记录</div>
          <div class="text-sm text-red-600 mt-8">*邮寄黄金需要先填写收货地址</div>
          <div
              v-if="!addressInfo"
              style="background-color: #F5F5F5;height: 100px;line-height: 100px"
              class="text-black rounded-xl text-center "
              @click="ToFillAddress">
            完善收货地址>>
          </div>
          <div
              v-else
              style="background-color: #F5F5F5;height: 100px;"
              class="text-black rounded-xl flex flex-col p-4"
              @click="ToFillAddress">
            <div class="flex flex-row  justify-between items-center">
              <div>{{ addressInfo.name }} {{ addressInfo.phone|maskPhoneNumber }}</div>
              <div class="text-red-600 ">更改</div>
            </div>
            <div >{{ addressInfo.detail_address }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";


export default {
  components: {NavBar, Button, },
  computed: {
    ...mapState("user", ["userInfo", "userWallet"]),
  },

  data() {
    return {
      title: this.$route.meta.title,
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
      addressInfo: null,
      withdrawQuotaList: [],
      selected:0,
      selectedAmount:0
    }
  },
  filters: {
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },
  mounted() {
    this.loadData();
    this.getUserWallet();
    // setInterval(this.updateCountdown, 1000); // 每秒更新一次倒计时
  },
  created() {
    this.getWithdrawQuotaList();
  },

  methods: {
    ...mapActions("user", ["getUserWallet"]),

    ToFillAddress() {
      this.$router.push({
        name: "withdrawGoldAddress",
        query: {
          name: this.addressInfo?.name,
          phone: this.addressInfo?.phone,
          detail_address:this.addressInfo?.detail_address,
        },
      });
    },
    jumpToRecord() {
      // if (isNullOrEmpty(this.addressInfo)) {
      //   this.$toast("你没有申请过提现");
      //   return
      // }
      console.log('跳转到提现记录',this.addressInfo)
      this.$router.push({
        name:'withdrawGoldRecord',
        query: {name: this.addressInfo.name,
          phone: this.addressInfo.phone,
          detail_address:this.addressInfo.detail_address},
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getWithdrawQuotaList() {
      axios.get(api.getWithdrawQuotaList, {params: {coin_name: this.data.type}}).then((res) => {
        this.withdrawQuotaList = res.data;
        this.selectedAmount = res.data[0];
      }).catch((err) => {
        console.log(err);
      });
    },
    formatAmount(amount) {
      if(amount&&amount.num){
        return `${amount.num}g`;
      }
      return ""
    },
    selectAmount(index) {
      this.selected = index;
      this.selectedAmount = this.withdrawQuotaList[index];
    },
    loadData() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      axios
          .get(api.myAddress)
          .then((res) => {
            this.addressInfo = res.data;
            this.$toast.clear();
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },

    onSubmit() {
      const {selectedAmount} = this;
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      console.log('提交中',selectedAmount)
      axios.post(api.userWithdrawal, {
        num: selectedAmount.num,
        coin_type: this.data.type,
      }).then((res) => {
        this.$toast.clear();
        this.$toast("申请邮寄成功");
        this.getUserWallet();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        this.$dialog.alert({
          title: '提示',
          message: err.msg || '申请邮寄失败',
        })
            .then(() => {
              if (err.code == 202) {
                this.$router.push({
                  name: "paySXFee",
                  query:
                      {
                        title: '缴纳加工费',
                        source: 3,
                        project_id: 7,
                        amount: err.data.price
                      }
                })
              }
            })
            .catch(() => {
            });
      });
    },
  },


}
</script>

<style scoped lang="less">
.page-container {
  background: url("../../assets/img/profile/bg-gold.png") no-repeat;
  background-size: 100% auto;
}

.page-container /deep/ .van-nav-bar .van-nav-bar__title {
  color: #fff;
}

.page-container /deep/ .van-nav-bar .van-icon {
  color: #fff;
}

.page-container /deep/ .van-nav-bar__text {
  color: #fff;
}

.item-box {
  margin-top: 40px;
  margin-bottom: 36px;

  .item {
    height: 55px;
    margin-top: 22px;
    display: flex;
    border: grey solid 1px;
    border-radius: 10px;
    align-items: center;
    padding: 0px 22px;

    span {
      color: #424141;
      width: 8%;
      background: none;
      font-size: 20px;

    }


    input {
      width: 97%;
      padding-left: 3%;
      height: 100%;
      background: none;
      font-size: 20px;

      color: #333;

    }

    input::placeholder {
      color: rgba(128, 128, 128, 0.5);
    }
  }
}

</style>
