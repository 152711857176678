<template>
  <div class="h-full w-full ">
    <div class="flex-grow overflow-hidden w-full">
      <div class="page-content-2">
        <div class="bg-top">
          <div class=" text-black ">
            <!-- Header -->
            <div class="ml-8 text-red-600 mt-4">
              <h1 class=" font-bold" style="font-size: 30px;">一邀一富</h1>
              <p style="font-size: 16px ;margin-bottom: 20px">邀请好友获取奖励</p>
            </div>
            <!-- Statistics -->
            <div class="grid grid-cols-2 gap-2 text-center px-4 text-lg ">
              <div v-for="(item, index) in levelList" :key="index"
                   class="rounded-xl flex flex-col justify-center items-center bg-white py-2">

                <div class="flex flex-nowrap items-start" style="margin-right: 67px">
                  <img :src="item.icon" alt="Level icon" class="mb-2" style="height: 30px; width: 30px;"/>
                  <p class="font-bold text-xl text-red-600">{{ item.count }}</p>
                </div>

                <p class="text-gray-500">{{ item.name }}总人数</p>
              </div>
            </div>
          </div>
        </div>
        <Notice/>
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="true"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div class="px-4 py-2">
              <div class="flex flex-row items-center bg-white rounded-t-xl">
                <h2 class="text-lg font-bold p-4 mr-1">任务列表</h2>
<!--                <CountDownTimer/>-->
              </div>

              <div v-for="(task, index) in list" :key="index"
                   class="bg-white px-2 pb-4  flex flex-row items-center">
                <img :src="task.icon" alt="Task icon" class="mr-1" style="width: 60px;"/>
                <div class="flex flex-col text-base flex-1 relative">
                  <div class="">邀请{{ task.invite_identity_user }}人实名注册</div>
                  <div class="">每日分红<span style="color: red">{{ task.invite_award_num|formatDecimal }}</span>扶持金</div>
                  <img src="@/assets/img/invite/three.png" style="width: 50px;"
                       class="absolute top-1/2 right-0   -translate-y-1/2"/>
                </div>
                <div class="text-sm text-white py-2 rounded text-center"
                     style="width: 70px"
                     v-html="getFormatLabel(task.status)"
                     :class="classObject(task)"
                     @click="jumpToPage(task)">
                </div>
              </div>
            </div>
          </list>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>


import {List, PullRefresh,} from "vant";
import {mapActions, mapState} from "vuex";
import Notice from "@/components/Notice";
import axios from "@/net/axios";
import api from "@/net/api";
import CountDownTimer from "@/components/CountDownTimer";

export default {
  name: "index",
  components: {CountDownTimer,   List,  PullRefresh, Notice},
  data() {
    return {
      list: [],
      levelList: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  filters: {
    formatLabel(item, userInfo) {
      if (userInfo?.tjnum >= item.recommendNum) {
        return '  已达标  '
      }
      return '未达标'
    },
    formatDecimal(value) {
      if (!value) {
        return '0';
      }
      return (parseFloat(value) / 10000).toFixed(0) + '万';
    }
  },
  mounted() {
    this.getUserInfo();
    this.loadMore();
  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),
    getFormatLabel(status) {
      switch (status) {
        case 2:
        case 3:
          return "&nbsp;&nbsp;已达标&nbsp;&nbsp;"
        default:
          return "&nbsp;&nbsp;未达标&nbsp;&nbsp;"
      }
    },
    jumpToPage(item) {
      switch (item.status) {
        case 2:
        case 3:
          this.$toast('该奖项已被领取');
          break;
        default:
          this.$router.push({name: 'inviteUser'})
          break;
      }
    },
    receiveAward(id) {
      axios
          .post(api.receiveAward, {id})
          .then((res) => {
            this.$toast("领取成功");
            this.loadMore();
            this.dialog = false;
          })
          .catch((err) => {
            this.dialog = false;
            err.msg && this.$toast(err.msg);
          });

    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            this.list = res.data.inviteConfigList
            this.loading = false
            this.refreshing = false
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = true
            err.msg && this.$toast(err.msg);
          });
      axios
          .get(api.levelList)
          .then((res) => {
            this.levelList = res.data.inviteConfigList
          })
          .catch((err) => {
          });
    },
    classObject(item) {
      switch (item.status) {
        case 2:
        case 3:
          return 'btn-complete';
        default:
          return 'btn-normal';
      }
    },
  },
};
</script>

<style scoped lang="less">
.invite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;

  width: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  height: calc((318 / 694) * 100vw);
  color: #FDE0B4;
  font-size: 14px;

  .btn {
    background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
    border-radius: 16px;
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #AB0F13;
  }
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-top {
  background-image: url("../../assets/img/index/bg_top2.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.btn-normal {
  background: linear-gradient(90deg, #FF5455, #FFB533);
  color: white;
}

.btn-complete {
  background: #8B7073;
  color: white;
}

.btn-receipt {
  background: #AB0F13;
  color: white;
}
</style>
