<template>
  <div class="page-container h-screen  bg">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full p-4">
        <div class="bg-white rounded-lg p-4 mb-2">
          <div class="flex justify-between items-start mb-2">
            <span class="text-sm text-gray-600">订单号: {{ data.no }}</span>
            <span :class="getStatusClass(data.status)">{{getStatusStr(data.status) }}</span>
          </div>
          <div class="flex items-center">
            <img src="@/assets/img/profile/gold-img.png" alt="Gold bars"
                 class="w-16 h-16 object-cover rounded-md mr-4"/>
            <div class="flex-1">
              <h2 class="font-bold text-lg">黄金邮寄</h2>
              <p class="text-red-500 font-bold">重量: {{ data.num }}g</p>
              <p class="text-gray-600">运费: 免费</p>
            </div>
          </div>

        </div>
        <div class="bg-white rounded-lg p-4 shadow-md ">
          <div class="mb-4">
            <h2 class="text-lg font-bold flex items-center">
              <span
                  class="w-6 h-6 bg-red-500 rounded-full flex text-sm items-center justify-center text-white mr-2">收</span>
              配送信息
            </h2>
            <div class="mt-2 text-base">
              <div class="flex justify-between">
                <span>姓名: {{ data.name }}</span>
                <span>电话: {{ data.phone }}</span>
              </div>
              <div class="mt-1">地址: {{ data.detail_address }}</div>
            </div>
          </div>

          <div class="border-t pt-4">
            <div class="flex items-center text-sm text-gray-600 mb-2">
              <img src="@/assets/img/profile/yt-logo.png" alt="圆通快递" class="w-6 h-6 mr-2"/>
              圆通快递 YT747801928452256
            </div>

            <div class="relative">
              <div class="absolute left-2 top-0 bottom-0 w-0.5 bg-gray-200"></div>
              <div class="space-y-4">
                <TimelineItem date="07-06 20:29" title="申请中" :active="true" description="正在审核申请黄金数量"/>
<!--                <TimelineItem date="07-06 21:29" title="审核通过" description="审核通过准备黄金邮寄"/>-->
<!--                <TimelineItem date="07-06 21:29" title="已取件" description="包裹正在等待揽收"/>-->
<!--                <TimelineItem date="07-06 21:29" title="已发出" description="您的黄金已别揽收"/>-->
<!--                <TimelineItem date="07-06 21:29" title="派送中" description="您的黄金正在派送中"/>-->
<!--                <TimelineItem date="07-06 21:29" title="已送达"   description="您的黄金已送达请及时查收"/>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions} from "vuex";
import {NavBar} from "vant";
import TimelineItem from "@/components/TimelineItem";

export default {
  components: {NavBar, TimelineItem},
  data() {
    return {
      title: this.$route.meta.title,
      data: this.$route.query,
    }
  },


  mounted() {
    console.log('参数',this.$route.query);
  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    getStatusClass(status) {
      switch (status) {
        case 0:
          return 'text-blue-500';
        case 2:
          return 'text-blue-500';
        case 1:
          return 'text-blue-500';
        case 3:
          return 'text-red-500';
        default:
          return 'text-gray-500';
      }
    },
    getStatusStr(status) {
      switch (status) {
        case 0:
          return '待审核';
        case 1:
          return '发货中';
        case 2:
          return '已完成';
        case 3:
          return '审核失败';
        default:
          return '';
      }
    },
  },

}
</script>

<style scoped lang="less">
.page-container /deep/ .van-field__label {
  font-weight: bold;
}

</style>
