<template>
  <div class=" h-full w-full">
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <div class="bg-top">
          <div class="pl-2 pt-2 ml-2">
            <div class="text-2xl font-bold text-white">一带一路</div>
            <div class="text-base text-white">参与一带一路项目获得股权分红</div>

          </div>
          <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
            <list
                v-model="loading"
                :finished="finished"
                @load="loadMore"
                @refresh="refreshData"
            >

              <!-- Products Section -->
              <div class="w-full flex flex-col items-center px-4 " style="margin-top: 270px">
<!--                <CountDownLivingTimer/>-->
                <div v-for="(product,index) in products" :key="product.id"
                     class="bg-white w-full mb-2 px-2 py-2 rounded-lg">
                  <div class=" flex flex-row items-center text-lg font-bold mb-2 ">
                    <div class="flex row items-center">
                    <img src="@/assets/img/livingsecurity/ic_diamond.png" class="product_image  ">
                    {{ product.name }}
                    </div>
                    <img v-if="index==2" src="@/assets/img/livingsecurity/zyyxt.png" style="height: 20px;">
                  </div>
                  <div class=" flex  justify-between items-center ">
                    <img src="@/assets/img/livingsecurity/ic_ImgUp.png" style="height: 80px">
                    <div class="flex flex-col text-base">
                      <p class="font-bold" style="margin-bottom: 5px ">价格: <span
                          class="text-red-500 font-bold">{{ product.price|formatDecimal }}</span>元</p>
                      <p class="font-bold" style="margin-bottom: 5px ">赠送: <span
                          class="text-red-500 font-bold">{{ product.gift_num|formatDecimal }}</span>股权</p>
                      <p class="font-bold" style="margin-bottom: 5px ">每日分红: <span
                          class="text-red-500 font-bold">{{ product.day_income|formatDecimal }}</span>元</p>
                    </div>
                    <button class="btn text-white text-center rounded-full  "
                            style="margin-left: 5px;height: 40px ;width: 100px" @click="jumpToPay(product)">立即购买
                    </button>
                  </div>
                </div>
              </div>
            </list>
          </PullRefresh>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {List, PullRefresh,} from "vant";
import {mapActions, mapState} from "vuex";
import axios from "@/net/axios";
import api from "@/net/api";
import CountDownLivingTimer from "@/components/CountDownLivingTimer";

export default {
  name: "index",
  components: { List,  PullRefresh, CountDownLivingTimer},
  data() {
    return {
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      loading: false,
      finished: false,
      refreshing: false,
      products: [
        {id: 1, price: 300, shares: 3000, dailyBonus: 30, image: '@/assets/product1.png'},
        {id: 2, price: 600, shares: 6000, dailyBonus: 60, image: '@/assets/product2.png'},
        {id: 3, price: 1200, shares: 12000, dailyBonus: 120, image: '@/assets/product3.png'},
        {id: 4, price: 3000, shares: 30000, dailyBonus: 300, image: '@/assets/product4.png'},
        {id: 5, price: 6000, shares: 60000, dailyBonus: 600, image: '@/assets/product5.png'},
      ],
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      console.log(this.identifyInfo);
      if (this.identifyInfo && this.identifyInfo.name) {
        return this.identifyInfo.name;
      } else {
        return "未设置";
      }
    },
  },
  filters: {
    formatDecimal(value) {
      if (!value) {
        return 0
      }
      return parseFloat(value).toFixed(0);
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },
  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getIdentifyInfo();
  },

  methods: {
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios.get(api.welfareList, {params: {type: 2}})
          .then(res => {
            this.products = res.data.idoList
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch(err => {
            this.loading = false
            this.finished = true
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToPay(data) {
      this.$router.push({
        name: "buyDividend",
        query: {
          id: data.id,
          stock: data.gift_num + '股',
          subTitle: '赠送',
          rate: data.day_income + '元',
          subTitle2: '每日分红',
          price: data.price,
          title: data.title,
          source: 2,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">

.btn {
  background: linear-gradient(90deg, #FFAB19, #BE0000);
}

.bg-title {
  background-image: url('../../assets/img/livingsecurity/bg_title.png');
  background-size: 100% 80px;
  background-repeat: no-repeat;
  padding: 10px 20px;
}

.item {
  background: #FEF8F6;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-pull-refresh {
  overflow-y: auto;
}

.bg-top {
  background-image: url("../../assets/img/livingsecurity/bg_living_top.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.product_image {
  margin-right: 10px;
  height: 20px;
}
</style>
