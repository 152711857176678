<template>
  <div class="page_record h-full w-full flex flex-col bg-gray-100">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        class="bg-transparent"
        :safe-area-inset-top="true"
    />

    <div class="overflow-hidden w-full flex-1">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <div class="px-4 py-6 bg-transparent">
            <div class="flex justify-center items-center">
              <div class="text-center mr-16">
                <p class="text-gray-600 text-sm">累计赠送</p>
                <p class="text-red-500 text-xl font-bold">{{ this.totalStock |formatDecimal2 }}股权</p>
              </div>
              <div class="text-center">
                <p class="text-gray-600 text-sm">累计日分红</p>
                <p class="text-red-500 text-xl font-bold">{{ this.totalDayAward || '0.00' }}元</p>
              </div>
            </div>
          </div>
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
          >
            <div v-for="(item, index) in list" :key="index" class="flex flex-col bg-white rounded-lg overflow-hidden mb-3 mx-4">
              <div class=" text-white py-2 px-4"
              style=" background: linear-gradient(to right, #F2AC7C, #BE0000);">
                订单号：{{ item.no}}
              </div>
              <div class="px-4 py-2l">
                <div class="flex items-center my-2 w-full">
                  <img src="@/assets/img/profile/stock_icon.png" alt="Stock Icon" class=" mr-4" style="height: 90px">
                  <div class="flex flex-col w-full">

                    <div class="flex justify-between items-center mb-2 w-full" >
                      <h3 class="font-bold flex-1 " >一带一路股权</h3>
                      <div class="text-red-500 flex items-center justify-center  ">
                        <img src="@/assets/img/profile/profit_icon.png" alt="Profit Icon" class="w-4 h-4 mr-1">
                        收益中
                      </div>
                    </div>

                    <div>
                      <div class="flex  text-sm">
                        <span class="text-gray-600">支付金额：</span>
                        <span class="text-red-500">{{ item.invest_money || '' }}元</span>
                      </div>
                      <div class="flex  text-sm">
                        <span class="text-gray-600">赠&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;送：</span>
                        <span class="text-red-500">{{ item.name || '' }}</span>
                      </div>
                      <div class="flex  text-sm">
                        <span class="text-gray-600">每日分红：</span>
                        <span class="text-red-500">{{ item.day_income | formatDecimal }}元</span>
                      </div>
                    </div>

                  </div>


                </div>


              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import {Empty, List, NavBar, PullRefresh,} from "vant";
import {isNullOrEmpty} from "@/utils";

export default {
  name: "index",
  components: { NavBar, List, Empty, PullRefresh},
  data() {
    return {
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
      totalStock:'',
      totalDayAward:'',
    };
  },
  computed: {
    title() {
      return "购买记录";
    }
  },
  filters: {
    formatDecimal(value) {
      if(isNullOrEmpty(value))
        return '0.00';
      return parseFloat(value).toFixed(2);
    },
    formatDecimal2(value) {
      if(isNullOrEmpty(value))
        return '0';
      return parseFloat(value).toFixed(0);
    },
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    refreshData() {
      this.finished = false
      this.refreshing = true;
      this.pageNo = 1
      this.totalPage = 0
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.buyBonusList, {})
          .then((res) => {
            this.list = res.data.idoList;
            this.loading = false
            this.refreshing = false
            this.finished = true;
            this.totalStock=res.data.totalStock;
            this.totalDayAward=res.data.totalDayAward;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },
    getTeamStatistics() {
      axios
          .post(api.buyBonusList, {})
          .then((res) => {
            this.childCount = res.data.idoList;
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>

<style scoped>
.page_record {
  background-color: #f5f5f5;
}
.mb-2{
  line-height: 24px;
}
</style>
