<template>
  <div class="h-full w-full bg-D92D23 ">
    <div class=" overflow-hidden w-full">
      <div class="page-content-2  px-4">
        <div class="bg-buy flex flex-col ">
          <div class="bg-FFECD4 text-black rounded-lg shadow-lg p-4 mt-6  flex flex-col justify-center items-center"
               style="margin-top: 340px;">
            <div class="text-lg">
              当前邀请人数
            </div>
            <div class="text-xl font-bold mt-2">
              {{ shareData.shareAwardCount }}人
            </div>
            <div class="flex items-center justify-center mt-2">
              <div class="bg-F19149 text-white rounded-lg px-2 py-1">
                已获
              </div>
              <div class="text-2xl font-bold text-red-600 ml-2">
                ¥ {{ shareData.shareAwardNum }}元
              </div>
            </div>
          </div>
          <div class="bg-FFECD4 text-black rounded-lg shadow-lg p-4 mt-4 ">
            <div>
              <CountDownGmjlTimer/>
            </div>
            <div class="flex items-center">
              <div class="relative">
                <img src="@/assets/img/invite/icon.png" style="height: 70px;"/>
                <img src="@/assets/img/invite/wbhd.png" class="absolute bottom-0 right-0" style="height: 50px;"/>
              </div>


              <div class="ml-4">
                <div class="text-lg">
                  每邀请1位用户购买一带一路股权
                </div>
                <div class="flex items-center mt-1">
                  <div class="bg-red-600 text-white rounded-lg px-2 py-1">
                    送
                  </div>
                  <div class="text-xl text-red-600 font-bold ml-2">
                    现金奖励50万元
                  </div>
                </div>
                <div class="text-gray-600 mt-1">
                  无限制领取，多邀多送！
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <img src="@/assets/img/invite/qyq.png" style="height: 46px;" @click="jumpToPage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {isNullOrEmpty} from "@/utils";
import axios from "@/net/axios";
import api from "@/net/api";
import moment from "moment";
import CountDownGmjlTimer from "@/components/CountDownGmjlTimer";

export default {
  components: {
    CountDownGmjlTimer
  },
  name: "Fund",
  data() {
    return {
      isNullOrEmpty,
      shareData: {},
      loading: false,
      finished: false,
      refreshing: false,
      dateTime: moment().format('YYYY年MM月DD日HH时mm分')
    };
  },
  mounted() {
    this.loadMore()
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的title值
    }
  },
  filters: {
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      return parseFloat(value / 10000).toFixed(0);
    }
  },
  methods: {
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.shareData)
          .then((res) => {
            this.shareData = res.data.data
            this.loading = false
            this.refreshing = false
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = true
            err.msg && this.$toast(err.msg);
          });
    },

    getFormatLabel(status) {
      switch (status) {
        case 2:
        case 3:
          return "&nbsp;&nbsp;已领取&nbsp;&nbsp;"
        default:
          return "立即邀请"
      }
    },
    classObject(item) {
      switch (item.status) {
        case 2:
        case 3:
          return 'btn-complete';
        default:
          return 'btn-normal';
      }
    },
    jumpToPage() {
      this.$router.push({name: 'inviteUser'})
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-body {

}

.bg-buy {
  background-image: url('../../assets/img/invite/yqjs.png');
  background-size: 80% auto;
  background-position: center top;
  background-repeat: no-repeat;
}


.tab {
  width: 126px;
  height: 40px;
  line-height: 40px;
}

.tab-checked {
  width: 126px;
  height: 40px;
  line-height: 40px;
}


.btn {
  background: linear-gradient(90deg, #FF5455, #FFB533);
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.btn-normal {
  background: #E60012;
  color: white;
}

.btn-complete {
  background: #EAD6BD;
  color: #B89494;
}

.btn-receipt {
  background: linear-gradient(90deg, #FF5455, #FFB533);
  color: white;
}
</style>
