<template>
  <div class="page-container h-screen  text-black">
    <nav-bar
        :title="data.title.concat('提现')"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent text-white "
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full ">
      <div class="overflow-y-auto h-full">
        <div class="text-center mt-4">
          <p class=" text-sm py-2 rounded w-auto " style="background: #DCDCDC;color: #CF0010;">提现如果遇到高峰期，可能会延迟到账</p>
          <h1 class=" font-bold mt-4" style=" color: #CF0010;font-size:40px">{{ balance }}</h1>
          <p style="font-size: 20px;margin-bottom: 20px">总金额(元)</p>
          <!--          <CountDownGqfhTimer />-->
        </div>

        <div class="bg-white rounded-t-lg p-4 mt-6 w-full">
          <h2 class="text-black text-lg font-bold mb-4">股权分红 • 申请提现</h2>
          <!-- 固定金额选择 -->
          <div class="mb-4">
            <div class="grid grid-cols-3 gap-2">
              <Button
                  v-for="(amount,index) in withdrawQuotaList"
                  :key="amount.id"
                  :type="selected === index ? 'danger' : 'default'"
                  class="w-full"
                  @click="selectAmount(index)"
              >
                {{ formatAmount(amount) }}
              </Button>
            </div>
          </div>

          <!-- 当前选择金额 -->
          <div class="text-sm text-gray-600 mb-4">
            当前选择 <span class="text-red-500 font-bold">{{ formatAmount(selectedAmount) }}</span>
          </div>

          <Button type="primary" round block color="#CF0010" @click="onSubmit" :disabled="btnDisabled" class="w-full "
                  style="margin-bottom: 36px;font-size: 18px">
            立即提现
          </Button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import CountDownGqfhTimer from "@/components/CountDownGqfhTimer";


export default {
  components: {NavBar, Button, CountDownGqfhTimer},
  computed: {
    ...mapState("user", ["userInfo"]),
    disabled() {
      return this.countdown.seconds > 0 || this.countdown.minutes > 0 || this.countdown.hours > 0 || this.countdown.days > 0
    },
    formatCountdown() {
      let countdownStr = ''
      if (this.countdown.days > 0) {
        countdownStr = `${this.countdown.days}天${this.countdown.hours}时${this.countdown.minutes}分${this.countdown.seconds}秒`
      } else {
        if (this.countdown.hours > 0) {
          countdownStr = `${this.countdown.hours}时${this.countdown.minutes}分${this.countdown.seconds}秒`
        } else {
          if (this.countdown.minutes > 0) {
            countdownStr = `${this.countdown.minutes}分${this.countdown.seconds}秒`
          } else {
            countdownStr = `${this.countdown.seconds}秒`
          }
        }
      }
      return countdownStr
    }
  },
  data() {
    return {
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
      targetDate: new Date('2024-08-18T00:00:00'),
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      fixedAmounts: [30, 100, 300, 500, 1000],
      selectedAmount: 30,
      withdrawQuotaList: [],
      selected: 0,
      bankCardInfo: {},
      btnDisabled:true,
    }
  },

  created() {
    this.getWithdrawQuotaList();
  },
  mounted() {
    this.getBankCardInfo();
  },
  methods: {
    ...mapActions("user", ["getUserWallet"]),
    getBankCardInfo() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      axios.get(api.myBankOrder, {params: {type: 1}})
          .then((res) => {
            this.$toast.clear();
            this.bankCardInfo = res.data;
            this.btnDisabled = false;
          })
          .catch((err) => {
            this.$toast.clear();
            this.btnDisabled = false;
            console.log(err);
          });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.go(-1);
    },
    formatAmount(amount) {
      if (amount && amount.num) {
        return `${(amount.num / 10000).toFixed(0)}万`;
      }
      return ""
    },
    selectAmount(index) {
      this.selected = index;
      this.selectedAmount = this.withdrawQuotaList[index];
    },
    getWithdrawQuotaList() {
      axios.get(api.getWithdrawQuotaList, {params: {coin_name: this.data.type}}).then((res) => {
        this.withdrawQuotaList = res.data;
        this.selectedAmount = res.data[0];
      }).catch((err) => {
        console.log(err);
      });
    },
    onSubmit() {
      if (this.bankCardInfo.status == 1) {
        this.$router.push({
          name: "pinganBankCard"
        })
        return;
      }

      const {selectedAmount} = this;

      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      axios.post(api.userWithdrawal, {
        num: selectedAmount.num,
        coin_type: this.data.type,
      }).then((res) => {
        this.$toast.clear();
        this.$toast("申请成功，正在审核");
        this.getUserWallet();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        this.$dialog.alert({
          title: '提示',
          message: err.msg || '提现失败',
        })
            .then(() => {
              if (err.code == 202) {
                this.$router.push({
                  name: "paySXFee",
                  query:
                      {
                        title: '国家纳税',
                        source: 3,
                        project_id: 5,
                        amount: err.data.price
                      }
                })
              }
            })
            .catch(() => {
            });
      });
    },
  },


}
</script>

<style scoped lang="less">
.page-container {
  background-image: url("../../assets/img/index/bg_top3.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

}

.item-box {
  margin-top: 40px;
  margin-bottom: 36px;

  .item {
    height: 55px;
    margin-top: 22px;
    display: flex;
    border: grey solid 1px;
    border-radius: 10px;
    align-items: center;
    padding: 0px 22px;

    span {
      width: 8%;
      background: none;
      font-size: 30px;
      font-weight: bold;
    }


    input {
      width: 97%;
      padding-left: 3%;
      height: 100%;
      background: none;
      font-size: 20px;
      font-weight: bold;
      color: #333;

    }

    input::placeholder {
      color: rgba(128, 128, 128, 0.5);
    }
  }
}


</style>
