<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div v-for="(item, index) in list" :key="index" class="flex flex-row items-center bg-white p-2 border-line"
                 :class="{ 'border-b': index !== list.length - 1 }">
              <div class="flex flex-col ml-1 flex-1   ">
                <div class="text-sm text-b333">{{ item.title }}</div>
                <div class="text-sm text-b666 flex flex-row  items-center">已获得收益:
                  <div class="text-red-500">{{ item.returnAmount || '0.00' }}</div>
                  元
                </div>
              </div>
              <div class="flex flex-col ml-1">
                <div class="text-sm text-b333 flex flex-row  items-center">
                  <div class="flex-1"/>
                  <div>每日赠送国债：</div>
                  <span class="text-red-500">{{item.givePrice || '0.00'}}元</span>
                </div>
                <div class="text-sm text-b666">{{ item.createtime | formatTimestamp('YYYY.MM.DD HH:mm') }}</div>
              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>


import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import {Empty, List, NavBar, PullRefresh,} from "vant";

export default {
  name: "index",
  components: {NavBar, List, Empty, PullRefresh},
  data() {
    return {
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {

  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    jumpToLevel(level) {
      this.$router.push({name: 'childTeam', params: {level: level}})
    },
    refreshData() {
      this.refreshing = true
      this.pageNo = 1
      this.totalPage = 0
      this.loadMore()
    },
    loadMore() {
      axios
          .post(api.buyList, {
            pageNo: this.pageNo,
          })
          .then((res) => {

            if (this.pageNo === 1) {
              this.list = res.data;
              this.totalPage = res.totalPage;
            } else {
              this.list = this.list.concat(res.data);
            }
            this.loading = false
            this.refreshing = false
            if (this.pageNo < this.totalPage) {
              this.pageNo++;
            } else {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },

  },
};
</script>

<style scoped lang="less">
</style>
