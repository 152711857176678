<template>
  <div v-if="users.length>0" class="bg-white rounded-lg p-4 mb-4">
    <div class="flex justify-between text-base text-gray-600 mb-2  p-2 bg-EAEAEA rounded text-black ">
      <span>用户</span>
      <span>获奖产品</span>
    </div>
    <div class="space-y-2">
      <div v-for="(user, index) in users" :key="index" class="flex justify-between text-sm">
        <span>{{ user.user_name }}</span>
        <span>{{ user|formatPrizeName }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";

export default {
  data() {
    return {
      users: []
    };
  },

  mounted() {
    this.loadData();
  },

  filters: {
    formatPrizeName(value) {
      if (value.type == 1) {
        return `获得${value.num}扶持金`
      } else {
        return `获得黄金${value.num}克`
      }
    },
  },
  methods: {
    loadData() {
      axios
          .get(api.awardList)
          .then((res) => {
            this.users=res.data.list||[]
          })
          .catch((err) => {
          });
    },
  },
};
</script>

<style lang="less" scoped>

</style>
