import { render, staticRenderFns } from "./withdrawGoldRecord.vue?vue&type=template&id=97939618&scoped=true"
import script from "./withdrawGoldRecord.vue?vue&type=script&lang=js"
export * from "./withdrawGoldRecord.vue?vue&type=script&lang=js"
import style0 from "./withdrawGoldRecord.vue?vue&type=style&index=0&id=97939618&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97939618",
  null
  
)

export default component.exports