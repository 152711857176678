<template>
  <div class="login_box overflow-y-auto">
    <div class=" login-box flex    ">
      <div class="flex flex-row items-center flex-1">
        <img src="../assets/img/small_icon.png" class="" alt="">
        <p class="name">共同富裕2025</p>
      </div>
      <img src="../assets/img/ic-close.png" style="height: 20px;" @click="$router.go(-1)" alt="">
    </div>

    <p class="head-tit ">会员注册</p>
    <div class="item-box">

      <div class="item">
        <span>手机号</span>
        <input
            v-model="mobile"
            maxlength="11"
            placeholder="请输入手机号"
            type="tel"
            @input="validateInput"
        />

      </div>
      <div class="item">
        <!-- <p class="name">推荐码</p> -->
        <span>邀请码</span>
        <input
            type="text"
            v-model="invitaCode"
            maxlength="11"
            placeholder="请输入邀请码"
        />

      </div>
      <div class="item">
        <span>密码</span>
        <!-- <p class="name">密码</p> -->
        <input
            type="password"
            v-model="password"
            maxlength="20"
            placeholder="设置6-18位字母数字密码"
        />

      </div>
      <div class="item">
        <span>密码</span>
        <!-- <p class="name">确认密码</p> -->
        <input
            type="password"
            v-model="password1"
            maxlength="20"
            placeholder="再次确认密码"
        />

      </div>
<!--      <div class="item flex flex-row items-center">-->
<!--        <span>验证码</span>-->
<!--        <div class="flex-1">-->
<!--          <input-->
<!--              type="text"-->
<!--              v-model="code"-->
<!--              maxlength="4"-->
<!--              placeholder="请输入验证码"-->
<!--          />-->
<!--        </div>-->
<!--        <img :src="imgCodeUrl" style="height: 36px;width:120px;margin-left: 10px" @click="loadCodeImage"/>-->
<!--      </div>-->
      <div class="flex justify-center">
        <button
            class="login_btn"
            @click="onRegister"
        >立即注册
        </button>
      </div>
      <div class="rember">已有共同富裕账号？<a href="./login">去登录</a></div>
    </div>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import UserStorage from "@/utils/user_storage";

export default {
  components: {},
  data() {
    return {
      mobile: "",
      password: "",
      password1: "",
      invitaCode: "",
      code: "",
      imgCodeUrl: "",
      key: null,

    };
  },

  mounted() {
    console.log("邀请码", this.$route.query.code);
    this.invitaCode = this.$route.query.code || "";
    // this.loadCodeImage();
  },
  methods: {
    validateInput() {
      // 移除非数字字符
      this.mobile = this.mobile.replace(/\D/g, '');
    },
    onRegister() {
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      if (isNullOrEmpty(this.password1)) {
        this.$toast("请再次输入密码");
        return;
      }
      if (this.password !== this.password1) {
        this.$toast("两次密码输入不一致");
        return;
      }
      if (isNullOrEmpty(this.invitaCode)) {
        this.$toast("请输入推荐码");
        return;
      }
      // if (isNullOrEmpty(this.code)) {
      //   this.$toast("请输入验证码");
      //   return;
      // }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });


      const formData = new FormData();
      formData.append('account', this.mobile);
      formData.append('code', this.invitaCode);
      formData.append('password', this.password);
      formData.append('re_password', this.password1);
      formData.append('captcha', this.code);
      formData.append('key', this.key);
      axios
          .post(api.register, formData)
          .then((res) => {
            this.$toast.clear();
            console.log("注册成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    loadCodeImage() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      axios
          .get(api.siteCode)
          .then((res) => {
            this.$toast.clear();
            console.log("验证码", res);
            this.key = res.data.key;
            this.imgCodeUrl = res.data.img;
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    }
  },
};
</script>
<style scoped lang="less">
.login_box {
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  background-size: cover;
  position: fixed;
  display: flex;
  padding: 30px 25px 25px 25px;
  flex-direction: column;

  .login-box {
    width: 100%;
    height: 22px;
  }

  img {
    width: 22px;
    margin-right: 10px;
  }

  .name {

    line-height: 22px;
    font-size: 22px;
    font-weight: 550;
    color: #CF0010;
  }

  .head-tit {
    padding-top: 10px;
    color: #CF0010;
    font-size: 14px;
  }

  .item-box {
    .item {
      height: 55px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 5px;
      margin-top: 22px;
      display: flex;
      align-items: center;
      padding: 0px 22px;
      flex-direction: row;

      span {
        width: 20%;
        background: none;
        font-size: 15px;
      }


      input {
        width: 97%;
        padding-left: 3%;
        height: 100%;
        background: none;
        font-size: 15px;
        color: #333;
      }

      input::placeholder {
        color: #808080;
      }
    }
  }

  .rember {
    margin-top: 20px;
    text-align: center;
    padding: 10px 0px;
  }

  .rember a {
    color: red;
  }

  .login_btn {
    margin-top: 40px;
    height: 50px;
    color: white;
    width: 60%;
    border-radius: 50px;

    background-color: #CF0010;
  }
}
</style>
