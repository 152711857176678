<template>
  <div class="h-full w-full ">
    <div class=" overflow-hidden w-full">
      <div class="page-content-2 ">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="true"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div style="padding-top: 20px;" class="bg-buy flex flex-col justify-center ">
              <div class="text-white text-xxl font-bold pl-4">推广注册</div>
              <div class="text-white text-base px-4 mb-14"><span class="font-bold">共同富裕声明：</span>扶贫金是靠大家宣传动起来拉起来的、而不是不劳而获、所有国家送的扶贫资金、是推动平台更多人参与共同致富落实贫困救济
              </div>
              <CountDownFpjTimer/>
              <div class="w-full px-4 ">
                <div class="bg-FEF9F1 w-full p-2 flex flex-col mb-4 rounded bg-white  "
                     v-for="(item,index) in list"
                     :key="index">
                  <div class="flex flex-row items-center p-2">
                    <div class="flex flex-row items-center mb-2 flex-1">
                      <img src="../../assets/img/invite/icon_reverse.png" style="height: 60px"/>
                      <div class="ml-2">
                        <div>邀请<span class="text-red-500 font-bold">{{ item.invite_identity_user }}</span>位实名注册</div>
                        <div class="my-2">立即获得</div>
                        <div><span class="text-red-500 font-bold">{{ item.invite_award_num }}</span>元扶贫金
                        </div>
                      </div>
                    </div>

                    <div
                        class="rounded-full  font-bold bg-E7CA98 py-1 px-2 text-sm flex flex-row justify-center items-center"
                        style="height: 30px;"
                        @click="jumpToPage(item,index)"
                        v-html="getFormatLabel(item.status)"
                        :class="classObject(item)"
                    >
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </list>
          <Empty v-if="isNullOrEmpty(list)&&loading===false&&refreshing==false" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>
import {Empty, List, PullRefresh} from "vant";
import {isNullOrEmpty} from "@/utils";
import axios from "@/net/axios";
import api from "@/net/api";
import moment from "moment";
import CountDownFpjTimer from "@/components/CountDownFpjTimer";

export default {
  name: "Fund",
  components: {List, Empty, PullRefresh,CountDownFpjTimer},
  data() {
    return {
      isNullOrEmpty,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      dateTime: moment().format('YYYY年MM月DD日HH时mm分')
    };
  },
  mounted() {
    this.loadMore()
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的title值
    }
  },
  filters: {
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      return parseFloat(value / 10000).toFixed(0);
    }
  },
  methods: {
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.myFupingAward)
          .then((res) => {
            this.list = res.data.inviteConfigList
            this.loading = false
            this.refreshing = false
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = true
            err.msg && this.$toast(err.msg);
          });
    },

    getFormatLabel(status) {
      switch (status) {
        case 2:
        case 3:
          return "&nbsp;&nbsp;已领取&nbsp;&nbsp;"
        default:
          return "立即邀请"
      }
    },
    classObject(item) {
      switch (item.status) {
        case 2:
        case 3:
          return 'btn-complete';
        default:
          return 'btn-normal';
      }
    },
    jumpToPage(item,index) {
      switch (item.status){
        case 2:
        case 3:
          this.$toast('该奖项已被领取');
          break;
        default:
          // if(index==0)
          // {
          //   this.$router.push({name: 'realNameAuth'})
          // }
          // else{
            this.$router.push({name: 'inviteUser'})
          // }
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-body {

}

.bg-buy {
  background-image: url('../../assets/img/invite/new_bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}


.tab {
  width: 126px;
  height: 40px;
  line-height: 40px;
}

.tab-checked {
  width: 126px;
  height: 40px;
  line-height: 40px;
}


.btn {
  background: linear-gradient(90deg, #FF5455, #FFB533);
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}
.btn-normal {
  background: #E60012;
  color: white;
}

.btn-complete {
  background: #EAD6BD;
  color: #B89494;
}

.btn-receipt {
  background: linear-gradient(90deg, #FF5455, #FFB533);
  color: white;
}
</style>
