<template>
  <div class="login_box">

    <div class="flex flex-col items-center justify-center">

      <img src="../assets/img/ic_launcher.png">
      <div class="font-bold text-white text-3xl mt-4">共同富裕2025</div>

    </div>

    <div class="item-box">
      <div class="item">

        <span>账号</span>
        <input
            type="tel"
            v-model="mobile"
            maxlength="11"
            placeholder="用户名"
        />

      </div>
      <div class="item flex flex-row items-center">
        <span>密码</span>
        <div class="flex-1">
          <input
              type="password"
              maxlength="20"
              v-model="password"
              placeholder="请输入你的密码"
          />
        </div>
        <div class="text-base" @click="jumpToPage('editPassword')">忘记密码?</div>
      </div>
      <div class="flex items-center py-2">
        <input
            type="checkbox"
            v-model="rememberPassword"
            id="rememberPassword"
            class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
        />
        <label for="rememberPassword" class="ml-2 block text-base text-gray-900">记住密码</label>
      </div>
      <button class="login_btn" @click="onLogin">立即登录</button>

      <div class="rember">还没共同富裕账号？ <a href="/register">去注册</a></div>

    </div>

  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";

import UserStorage from "@/utils/user_storage";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      mobile: "",
      password: "",
      loading: false,
      rememberPassword: true,
    };
  },
  mounted() {
    this.checkSavedCredentials();
    this.loginByToken();
  },
  methods: {
    onLogin() {
      // console.log("登录中1...");
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });

      const formData = new FormData();
      formData.append('account', this.mobile);
      formData.append('password', this.password);
      axios
          .post(api.login, formData)
          .then((res) => {
            this.$toast.clear();
            console.log("登录成功", res);
            this.saveCredentials();
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            console.log('登录失败', e)
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    loginByToken() {
      try {
        const token = this.$route.query.token;
        if (token) {
          UserStorage.setToken(token);
          window.token = token;
          this.$router.replace({name: "home"});
        }
      } catch (e) {
        console.log(e);
      }
    },
    mounted() {
      this.checkSavedCredentials();
    },
    checkSavedCredentials() {
      const savedCredentials = localStorage.getItem('savedCredentials');
      if (savedCredentials) {
        const {mobile, password} = JSON.parse(savedCredentials);
        this.mobile = mobile;
        this.password = password;
      }
    },
    saveCredentials() {
      if (this.rememberPassword) {
        localStorage.setItem('savedCredentials', JSON.stringify({
          mobile: this.mobile,
          password: this.password
        }));
      } else {
        localStorage.removeItem('savedCredentials');
      }
    },
    jumpToPage(name) {
      this.$router.push({name});
    },
  },
};
</script>
<style scoped lang="less">
.login_box {
  width: 100%;
  height: 100%;
  background: url("../assets/img/bg_login.png") no-repeat;
  background-size: 100% auto;
  position: fixed;
  display: flex;
  padding: 80px 25px 25px 25px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .name {
    font-size: 34px;
    font-weight: 550;
    color: #fff;
  }

  .head-tit {
    padding-top: 22px;
    color: #fff;
    font-size: 19px;
  }

  .item-box {
    margin-top: 40px;

    .item {
      height: 55px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
      margin-top: 22px;
      display: flex;
      align-items: center;
      padding: 0px 22px;


      span {
        width: 12.5%;
        background: none;
        font-size: 15px;
      }

      input {
        width: 97%;
        padding-left: 3%;
        height: 100%;
        background: none;
        font-size: 15px;
        color: #333;
      }

      input::placeholder {
        color: #808080;
      }
    }
  }

  .rember {
    margin-top: 20px;
    text-align: center;
    padding: 10px 0px;
    font-size: 14px;
  }

  .rember a {
    color: red;
  }

  .login_btn {
    margin-top: 40px;
    height: 50px;
    color: #333;
    width: 100%;
    border-radius: 50px;
    background-color: #fde0b4;
  }

  img {
    border: white solid 1px;
    border-radius: 12%;
    width: 35%;


  }
}
</style>
