// 该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
import Login from "@/pages/login";
import Register from "../pages/register";
import Home from "../pages/home";
import UserStorage from "../utils/user_storage";
import NewsDetails from "@/pages/news/NewsDetails";
import InviteUser from "@/pages/invite/user";
import Main from "@/pages/main";
import BuyBonusList from "@/pages/dividend/record";
import BuyDividend from "@/pages/dividend/buy";
import Profile from "@/pages/profile";
import UserInfo from "@/pages/profile/details";
import RealNameAuth from "@/pages/profile/realNameAuth";
import BindBankCard from "@/pages/profile/bindBankCard";
import Withdraw from "@/pages/profile/withdraw";
import WithdrawRecord from "@/pages/profile/withdrawRecord";
import FundDetails from "@/pages/profile/fundDetails";
import ContactUs from "@/pages/settings/contactUs";
import Team from "@/pages/team";
import ChildTeam from "@/pages/team/child";
import CustomService from "@/pages/settings/customService";
import LivingSecurity from "@/pages/livingsecurity";
import CenterFile from "@/pages/home/CenterFile";
import BuyDebt from "@/pages/debt/buy";
import BuyDebtRecord from "@/pages/debt/record";
import PayCardFee from "@/pages/dividend/payCardFee";
import Invite from "@/pages/invite";
import EditPassword from "@/pages/password/editPassword";
import WithdrawGold from "@/pages/profile/withdrawGold";
import Award from "@/pages/award";
import WithdrawGoldAddress from "@/pages/profile/withdrawGoldAddress.vue";
import WithdrawGoldRecord from "@/pages/profile/withdrawGoldRecord";
import MailProgress from "@/pages/profile/mailProgress";
import WithdrawStock from "@/pages/profile/withdrawStock";
import InviteNew from "@/pages/invite/invite-new";
import InviteNew2 from "@/pages/invite/invite-new2";
import WithdrawYqj from "@/pages/profile/withdrawYqj";
import PaySXFee from "@/pages/dividend/paySXFee";
import WithdrawGqfh from "@/pages/profile/withdrawGqfh";
import WithdrawGq from "@/pages/profile/withdrawGq";
import PinganBankCard from "@/pages/profile/pinganBankCard";
import BankCardAddress from "@/pages/profile/bankCardAddress";
//创建并暴露一个路由器
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Main,
            name: "main",
            meta: {title: "主页"},
            children: [
                {
                    path: "/home",
                    component: Home,
                    name: "home",
                    meta: {title: "首页"},
                },
                {
                    path: '/invite',
                    component: Invite,
                    name: 'invite',
                    meta: {title: '邀请'},
                }, {
                    path: '/invite-new',
                    component: InviteNew,
                    name: 'inviteNew',
                    meta: {title: '扶贫金'},
                }, {
                    path: '/invite-new2',
                    component: InviteNew2,
                    name: 'inviteNew2',
                    meta: {title: '购买奖励'},
                },
                {
                    path: "/livingsecurity",
                    component: LivingSecurity,
                    name: "livingsecurity",
                    meta: {title: "一带一路股权"},
                },

                {
                    path: "/profile",
                    component: Profile,
                    name: "profile",
                    meta: {title: "我的"},
                },
                {
                    path: '/',
                    redirect: '/home',
                },
            ]
        },
        {
            path: "/login",
            component: Login,
            name: "login",
            meta: {title: "登录"},
        },
        {
            path: "/register",
            component: Register,
            name: "register",
            meta: {title: "注册"},
        },

        {
            path: '/news/details/:id',
            component: NewsDetails,
            name: 'newsDetails',
            meta: {title: '新闻资讯'},
        },
        {
            path: '/contactus',
            component: ContactUs,
            name: 'contactUs',
            meta: {title: '官方群聊'},
        }, {
            path: '/customService',
            component: CustomService,
            name: 'customService',
            meta: {title: '在线客服'},
        },
        {
            path: '/invite/user',
            component: InviteUser,
            name: 'inviteUser',
            meta: {title: '邀请好友'},
        },
        {
            path: '/password/editPassword',
            component: EditPassword,
            name: 'editPassword',
            meta: {title: '修改密码'},
        },
        {
            path: '/team',
            component: Team,
            name: 'team',
            meta: {title: '我的团队'},
        },
        {
            path: '/team/child/:level',
            component: ChildTeam,
            name: 'childTeam',
            meta: {title: '我的团队'},
        },

        {
            path: "/dividend/buy",
            component: BuyDividend,
            name: "buyDividend",
            meta: {title: "立即购买"},
        },
        {
            path: "/dividend/record",
            component: BuyBonusList,
            name: "buyBonusList",
            meta: {title: "购买记录"},
        },
        {
            path: "/buyDebtRecord",
            component: BuyDebtRecord,
            name: "buyDebtRecord",
            meta: {title: "国债购买记录"},
        },
        {
            path: "/profile/details",
            component: UserInfo,
            name: "userInfo",
            meta: {title: "个人资料"},
        }, {
            path: "/profile/realNameAuth",
            component: RealNameAuth,
            name: "realNameAuth",
            meta: {title: "实名认证"},
        }, {
            path: "/profile/bindBankCard",
            component: BindBankCard,
            name: "bindBankCard",
            meta: {title: "完善银行卡信息"},
        },
        {
            path: "/profile/withdraw",
            component: Withdraw,
            name: "withdrawal",
            meta: {title: "提现"},
        },
        {
            path: "/profile/withdrawRecord",
            component: WithdrawRecord,
            name: "withdrawRecord",
            meta: {title: "提现记录"},
        }, {
            path: "/profile/fundDetails",
            component: FundDetails,
            name: "fundDetails",
            meta: {title: "资金明细"},
        }, {
            path: "/centerFile",
            component: CenterFile,
            name: "centerFile",
            meta: {title: "中央文件"},
        }, {
            path: "/buyDebt",
            component: BuyDebt,
            name: "buyDebt",
            meta: {title: "立即购买"},
        }, {
            path: "/payCardFee",
            component: PayCardFee,
            name: "payCardFee",
            meta: {title: "立即支付"},
        },  {
            path: "/withdrawGold",
            component: WithdrawGold,
            name: "withdrawGold",
            meta: {title: "邮寄黄金"},
        }, {
            path: "profile/withdrawGoldAddress",
            component: WithdrawGoldAddress,
            name: "withdrawGoldAddress",
            meta: {title: "完善邮寄地址"},
        },
       {
            path: "/withdrawGoldRecord",
            component: WithdrawGoldRecord,
            name: "withdrawGoldRecord",
            meta: {title: "抽奖"},
        }, {
            path: "/mailProgress",
            component: MailProgress,
            name: "mailProgress",
            meta: {title: "物流详情"},
        },{
            path: "/withdrawStock",
            component: WithdrawStock,
            name: "withdrawStock",
            meta: {title: "我的股权"},
        },
        {
            path: "/award",
            component: Award,
            name: "award",
            meta: {title: "抽奖"},
        }, {
            path: "/withdrawYqj",
            component: WithdrawYqj,
            name: "withdrawYqj",
            meta: {title: "邀请奖提现"},
        },{
            path: "/withdrawGqfh",
            component: WithdrawGqfh,
            name: "withdrawGqfh",
            meta: {title: "股权分红提现"},
        }, {
            path: "/paySXFee",
            component: PaySXFee,
            name: "paySXFee",
            meta: {title: "立即支付"},
        },
        {
            path: "/withdrawGq",
            component: WithdrawGq,
            name: "withdrawGq",
            meta: {title: "出售股权"},
        },
        {
            path: "/pinganBankCard",
            component: PinganBankCard,
            name: "pinganBankCard",
            meta: {title: "平安银行卡"},
        },
        {
            path: "/bankCardAddress",
            component: BankCardAddress,
            name: "bankCardAddress",
            meta: {title: "配送信息"},
        }
    ],
});

/**
 * 解决报错问题：报错显示是路由重复1
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    // 判断目标路由是否与当前路由相同
    if (location.path !== this.currentRoute.path) {
        return originalPush.call(this, location).catch(err => err);
    }
}
//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
    //切换路由，页面直接滚动到顶部
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
});

// 导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === "/login" || to.path === "/register" || to.path === "/password/editPassword") {
        // 如果目标页面是登录页，直接放行
        return next()
    }
    const isAuthenticated = UserStorage.getToken(); // 判断用户是否已登录的函数
    // 如果目标页面不是登录页
    if (!isAuthenticated && to.path !== '/login') {
        return next('/login')
    }
    next();
});

export default router;
