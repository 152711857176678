<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData">
        <div class="bg-red-500 text-white m-4 rounded-xl p-4">
          <div class="flex justify-between items-center">
            <div>
              <div class="text-xl font-bold">黄金工会</div>
              <div class="text-3xl font-bold mt-2">{{ this.childCount.totalAchievement || '0.00' }}</div>
              <div class="text-sm mt-1">总业绩</div>
            </div>
            <img src="@/assets/img/profile/crown.png" alt="Crown" class="w-16 h-16">
          </div>
          <div class="flex justify-between mt-4">
            <div>
              <div class="text-2xl font-bold">{{ this.childCount.zhi_num || '0' }}</div>
              <div class="text-sm">直推人数</div>
            </div>
            <div>
              <div class="text-2xl font-bold">{{ this.childCount.group_num || '0' }}</div>
              <div class="text-sm">团队总人数</div>
            </div>
          </div>
        </div>

        <div class="bg-white mx-4 rounded-xl">
          <tabs v-model="activeTab" sticky @click="onClick">
            <tab :title="oneLevel" name="0">
            </tab>
            <tab :title="twoLevel" name="1">
            </tab>
            <tab :title="threeLevel" name="2">
            </tab>
          </tabs>
        </div>

          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div v-for="(item, index) in list" :key="index" class="flex items-center p-4 border-b border-gray-200">
              <van-image
                  round
                  width="50px"
                  height="50px"
                  :src="item.head_img || require('@/assets/img/profile/d-avatar.png')"
              />
              <div class="ml-4 flex-1">
                <div class="flex justify-between">
                  <div>
                    <div class="font-bold">{{ item.real_name || '未实名' }}</div>
                    <div class="text-gray-500 text-sm">{{ item.username }}</div>
                  </div>
                  <div class="text-right">
                    <div>{{ item.created_at | timeFormat('YYYY/MM/DD') }}</div>
                    <div class="text-red-500">{{ item.achievement || '0.00' }}元 (业绩)</div>
                  </div>
                </div>
              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import {Empty, Image as VanImage, List, NavBar, PullRefresh, Tab, Tabs} from "vant";

export default {
  name: "index",
  components: {NavBar, List, VanImage, Empty, PullRefresh, Tab, Tabs},
  data() {
    return {
      count: 0,
      pageSize: 10,
      pageNo: 1,
      level: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
      activeTab: '0',
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return "我的团队";
    },
    oneLevel() {
       if (this.childCount.zhi_num>0) {
         return `直推好友(${this.childCount.zhi_num})`
       }
       return '直推好友'
    },
    twoLevel() {
      if (this.childCount.recommendTwoLevelNum>0) {
        return `二级好友(${this.childCount.recommendTwoLevelNum})`
      }
      return '二级好友'
    },
    threeLevel() {
      if (this.childCount.recommendThreeLevelNum>0) {
        return `三级好友(${this.childCount.recommendThreeLevelNum})`
      }
      return '三级好友'
    },
  },
  mounted() {
    this.getUserInfo();
    // this.loadMore();
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (newVal.isCaptain !== oldVal?.isCaptain) {
          this.getTeamStatistics()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onClick(name) {
      this.loading = true
      this.finished = false

      if (name === '0') {
        this.level = 1;

      } else if (name === '1') {

        this.level = 2;
      } else if (name === '2') {
        this.level = 3;
      }
      this.pageNo = 1;
      this.count = 0;
      this.loadMore();
    },
    refreshData() {
      this.finished = false
      this.refreshing = true
      this.pageNo = 1
      this.count = 0
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.teamList, {
            params: {
              level: this.level,
              page: this.pageNo,
              size: this.pageSize,
            }
          })
          .then((res) => {
            if (this.pageNo === 1) {
              this.list = res.data.list;
              this.count = res.data.total;
              this.childCount = res.data.userInfo;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.loading = false
            this.refreshing = false
            if (this.list.length < this.count) {
              this.pageNo++;
            } else {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>

<style scoped lang="less">
.van-tabs__wrap {
  padding-top: 10px;
}
</style>
