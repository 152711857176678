<template>
  <div class="page-container h-full w-full bg-top">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent text-white "
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <div class="w-full p-2">
          <img class="w-full" src="@/assets/img/centerFile/banner.png">
        </div>
        <div class="mx-2 mt-4 rounded-lg">
          <div class="text-lg font-bold flex flex-row items-center mb-2"><div class="bg-b333 rounded mr-1" style="height: 12px; width: 4px;background-color: #1a1d27"></div><div>中央文件列表</div></div>
          <div class="flex flex-wrap ">
            <div v-for="news in list" :key="news.id" class="w-1/2  px-2 mb-4" @click="jumpToPage(news.id)">
              <img :src="news.img" alt="News" class="w-full h-32 object-cover rounded">
              <p class="mt-2 text-sm">{{ news.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "CenterFile",
  components: {NavBar},
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },

  mounted() {
    this.getNewsList();
  },

  methods: {
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id,
          type:2
        }
      })
    },
    getNewsList() {
      axios
          .get(api.newsList,{params: {type: 2,index_status:0}})
          .then((res) => {
            this.list = res.data;
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
  },

};
</script>

<style scoped lang="less">
.index_box {
  margin-top: 48px;
  margin-bottom: 24px;
}

.bg-top {
  background: linear-gradient(0deg, #FAFAFA, #CF0010);
  background-size: 100% 50%;
  background-repeat: no-repeat;
}


.notice-title {
  width: 320px;
  height: 67px;
}


.company_news {
  font-size: 16px;
  margin-top: 20px;


  .news_text {
    font-weight: bold;
  }

  .Latest_News {
    margin-bottom: 4px;
    font-size: 12px;
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

}

.page-container /deep/ .van-nav-bar .van-nav-bar__title {
  color: #fff;
}

.page-container /deep/ .van-nav-bar .van-icon {
  color: #fff;
}
</style>
