<template>
  <div class="page-container  bg-F77726 bg-top flex flex-col">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
        :fixed="false"
    />
    <div class="text-2xl font-bold text-brown mb-4" style="color:#8F3414;font-size: 48px">平安银行</div>
    <div class="text-sm text-brown mb-4" style="color:#8F3414;font-size: 20px">为您资金安全保驾护航</div>
    <div class="m-4 rounded p-4 text-base text-white" style="background-color: #E55215; white-space: pre-wrap;">
      {{ '由于股权分红、邀请现金提现资金过大，导致风控打款失败。领导决定统一发放到新办的银行卡，当天办理当天发资，共同富裕平台总部与平安银行合作审批通过，银监会审批通过，同意所有提现资金到账平安银行。\n办理费1000元是存在平安银行里面的，因为需要银行卡里面有钱才能激活，相当于免费办理，国家不收任何一分钱。' }}
    </div>
    <div class="w-full p-4">
      <div class="bg-white rounded flex flex-col ">
        <div class="flex flex-row pt-4">
          <div class="text-white text-base p-2 rounded-r  mb-2" style="background-color: #FCC856;width:auto">平安银行银行卡
          </div>
        </div>
        <div class="px-4 pb-4">
          <BankCardGenerator :cardData="bankCardInfo"/>
        </div>
      </div>
      <div class="bg-white rounded p-4 my-4 ">
        <div class="text-lg font-semibold mb-2">激活使用</div>
        <div class="py-2 text-lg flex flex-row items-center justify-between">
          <div>激活<span class="text-red-500">¥1000</span></div>
          <img v-if="bankCardInfo.status == 1" src="@/assets/img/bank/jh.png" style="height: 40px" @click="toActive"/>
          <div v-else class="text-red-500">已激活</div>
        </div>
      </div>
      <div v-if="bankCardInfo.get_name" class="bg-white p-4 rounded">
        <div class="mb-4 flex justify-between items-center">
          <div class=" text-lg font-semibold">配送信息</div>
          <div class=" text-red-500 cursor-pointer  " @click.stop="editAddress">修改</div>
        </div>
        <div class="flex flex-row justify-between items-center">
          <div class="mb-1">姓名：{{ bankCardInfo.get_name }}</div>
          <div class="mr-10">电话：{{ bankCardInfo.get_mobile }}</div>
        </div>
        <div class="mb-1">地址：{{ bankCardInfo.get_address }}</div>
        <div v-if="bankCardInfo.status ==3">制卡进度：<span class="text-blue-500">登记完毕，等待邮寄员上门揽件</span></div>
      </div>
      <div v-else class="bg-white p-4   rounded " @click="editAddress">
        <div class="mb-4 text-lg font-semibold">配送信息</div>
        <div class="mb-4 text-base text-center">点击完善配送信息</div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import BankCardGenerator from "@/components/BankCardGenerator";

export default {
  components: {NavBar, BankCardGenerator},
  computed: {
    ...mapState("user", ["userInfo", "identifyInfo", "userWallet"]),
  },

  mounted() {
    this.getIdentifyInfo();
    this.getUserInfo();
    this.getBankCardInfo();
  },
  data() {
    return {
      activationAmount: '¥1000',
      name: '',
      idNumber: '',
      address: '',
      deliveryInfo: '',
      bankCardInfo: {
      }
    }
  },
  methods: {
    isNullOrEmpty,
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo", "getUserWallet"]),
    editAddress() {
      this.$router.push({
        name: "bankCardAddress",
        query: {
          id: this.bankCardInfo.id,
          get_name: this.bankCardInfo.get_name,
          get_mobile: this.bankCardInfo.get_mobile,
          get_address: this.bankCardInfo.get_address,
          id_card_no: this.bankCardInfo.id_card_no,
        },
      });
    },


    getBankCardInfo() {
      axios.get(api.myBankOrder, {params: {type: 1}})
          .then((res) => {
            this.bankCardInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },


    onClickLeft() {
      this.$router.go(-1);
    },
    toActive() {
      this.$router.push({
        name: "paySXFee",
        query:
            {
              title: '激活费',
              source: 4,
              project_id: 1,
              amount: 1000
            }
      })
    },
  },
  filters: {
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },


}
</script>

<style scoped lang="less">
.page-container /deep/ .van-nav-bar .van-icon {
  color: #fff;
}

.bg-top {
  background-image: url("../../assets/img/bank/bg_pingan.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>
