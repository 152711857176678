<template>

  <div class=" mt-2  p-4 rounded   mx-2" style="background: transparent">
    <div >
      <h2 class="text-lg font-bold ">热点新闻</h2>
      <p class="text-sm text-gray-500 mb-4 ">实时了解热点新闻动向</p>
    </div>
    <div class="flex flex-col -mx-2 bg-white pt-4 pb-2 rounded-md" >
      <div v-for="(news,index) in newsList"  @click="jumpToPage(news.id)" :key="news.id" class=" flex flex-nowrap justify-between items-center w-full  px-4 py-2 mb-2 ">

        <div class="flex flex-nowrap justify-center items-center font-bold w-full max-w-full overflow-hidden">
          <span :class="getIndexColor(index)" class="flex-shrink-0">{{index+1}}</span>
          <span class="text-lg mx-4 truncate flex-1 min-w-0">{{ news.title }}</span>
        </div>

        <img src="@/assets/img/index/ic_hot.png" alt="News" class=" h-5 object-cover rounded">

      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";
import index from "vuex";
import {ReviewStatus} from "@/config";

export default {
  computed: {
    index() {
      return index
    }
  },
  data() {
    return {
      newsList:[

      ],
    };
  },
  mounted: function () {
    this.getNewsList();
  },
  methods: {
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id,
          type:1
        }
      })
    },
    getIndexColor(index) {
      switch (index) {
        case ReviewStatus.PENDING:
          return 'text-red-500';
        case ReviewStatus.PAID:
          return 'text-orange-500';
        case ReviewStatus.PAYMENT_FAILED:
          return 'text-yellow-500';
        default:
          return 'text-black';
      }
    },

    getNewsList: function () {
      axios
          .get(api.newsList,{params: {type: 1,index_status:0}})
          .then((res) => {
            this.newsList = res.data;
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>
<style scoped lang="less">
.news-head {
  font-size: 16px;
  padding-top: 15px;
  font-weight: bold;
  color: #fff;
  text-align: left;
  padding-left: 10px;
}

.news-box {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.8);
  width: 92%;
  border-radius: 5px;
  margin: 15px auto 20px;
}

.news-box .item {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0px;
  position: relative;
  display: flex;
}
.news-box .item:last-child {
  border-bottom: none;
}

.news-box .left {
  width: 75%;
  padding-right: 5%;
}

.news-box .title {
  font-size: 14px;
  font-weight: 550;
  margin-top: 0px;
  color: #000;
  line-height: 22px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.news-box .lin-text {
  font-size: 12px;
  margin-top: 0px;
  color: #666;
  line-height: 20px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-box .img-box {
  width: 30%;
}
</style>
