<template>
  <div class="  h-full w-full">
    <div class="flex-grow overflow-hidden w-full">
      <div class=" page-content-2">

        <div class="user_info flex flex-row " style="margin-top: 40px">
          <div class="flex flex-row flex-1 items-center ">
            <div class="header">
              <a href="/profile/details">
                <img class="rounded-full" style="width: 80px;height: 80px"
                     :src="userInfo.head_img?userInfo.head_img: require('@/assets/img/d-avatar.png')"
                     alt=""></a>
            </div>
            <div class="base_info ">

              <div class="flex flex-nowrap justify-center items-center ml-2 "
                   style="font-size: 18px;font-weight: bold;color: black">{{ displayName }}
                <div v-if="userInfo.levelName!=0" class="flex  rounded justify-center items-center ml-2 "
                     style="height: 22px;width: 80px;background: #FFC600 ">
                  <img style="height: 16px" src="@/assets/img/yellow_diamond.png">
                  <span class="text-sm font-normal">{{ userInfo.levelName }}</span>
                </div>
              </div>
              <p style="color: black"><span style="color: #999999">手机号：</span>{{ userInfo.name|maskPhoneNumber }}</p>
              <p style="color: black"><span style="color: #999999">邀请码：</span>{{ userInfo.code }}</p>
              <p style="color: black">
                <span style="color: #999999">我的黄金：</span>
                <span style="color: red">{{ userWallet.gold ? userWallet.gold : '0.00' }}</span>
                克
              </p>
            </div>
          </div>
          <div class="flex flex-col mt-2">
            <button style="  background: linear-gradient(to right, #FF5455 ,#FFB533 );"
                    class=" text-white text-sm px-4 py-1 rounded-full" @click="onSignIn"> 每日签到
            </button>
            <button style="  background: linear-gradient(to right, #FF5455 ,#FFB533 );"
                    class=" text-white text-sm px-4 py-1 rounded-full mt-4" @click="onCopyText(url)">
              复制推广链接
            </button>
          </div>
        </div>

        <div class="bg-white grid grid-cols-3 gap-4 text-center py-4 rounded-lg  mt-4"
             style="margin-left: 20px;margin-right: 20px">

          <div>
            <div class="text-black text-base"> 扶持金</div>
            <div class="text-red-500 text-base flex flex-nowrap justify-center items-center ">
              {{ userWallet.invite_award |formatDecimal }}<span
                class="text-sm text-black">元</span></div>
            <button class="bg-red-500 text-white px-4 py-1 rounded-full mt-2 text-xs"
                    @click="jumpToWithdraw('invite_award','扶持金',userWallet.invite_award)"> 提现
            </button>
          </div>
          <div>
            <div class="text-black text-base"> 推荐奖</div>
            <div class="text-red-500 text-base flex flex-nowrap justify-center items-center ">
              {{ userWallet.recommend_award |formatDecimal }}<span
                class="text-sm text-black">元</span></div>
            <button class="bg-red-500 text-white px-4 py-1 rounded-full mt-2 text-xs"
                    @click="jumpToWithdraw('recommend_award','推荐奖',userWallet.recommend_award)"> 提现
            </button>
          </div>
          <div>
            <div class="text-black text-base"> 扶贫金</div>
            <div class="text-red-500 text-base flex flex-nowrap justify-center items-center ">
              {{ userWallet.fuping_wallet |formatDecimal }}<span
                class="text-sm text-black">元</span></div>
            <button class="bg-red-500 text-white px-4 py-1 rounded-full mt-2 text-xs"
                    @click="jumpToWithdraw('fuping_wallet','扶贫金',userWallet.fuping_wallet)"> 提现
            </button>
          </div>
          <div>
            <div class="text-black text-base"> 我的股权</div>
            <div class="text-red-500 text-base ">{{ userWallet.stock |formatDecimal }}
              <span class="text-sm text-black">股</span>
            </div>
            <button style="background: #EE7703" class=" text-white px-4 py-1 rounded-full mt-2 text-xs"
                    @click.stop="jumpToWithdraw('stock','我的股权',userWallet.stock)">出售
            </button>
          </div>

          <!--          <div>-->
          <!--            <div class="text-gray-500 text-base"> 黄金数量</div>-->
          <!--            <div class="text-red-500 text-xlg ">{{ userWallet.totalprofit ? userWallet.totalprofit : '0.00' }}<span-->
          <!--                class="text-sm">g</span></div>-->
          <!--            <button style="background-color: #FFA200" class=" text-white px-4 py-1 rounded-full mt-2 text-xs"-->
          <!--                    @click="jumpToPage('withdrawGold')"> 邮寄-->
          <!--            </button>-->
          <!--          </div>-->

          <div>
            <div class="text-black text-base"> 股权分红</div>
            <div class="text-red-500 text-base ">{{ userWallet.income_wallet |formatDecimal2 }}<span
                class="text-sm">元</span></div>
            <button class="bg-red-500 text-white px-4 py-1 rounded-full mt-2 text-xs"
                    @click="jumpToWithdraw('income_wallet','股权分红',userWallet.income_wallet)"> 提现
            </button>

          </div>
          <div>
            <div class="text-black text-base"> 邀请奖</div>
            <div class="text-red-500 text-base flex flex-nowrap justify-center items-center ">
              {{ userWallet.share_wallet |formatDecimal }}<span
                class="text-sm text-black">元</span></div>
            <button class="bg-red-500 text-white px-4 py-1 rounded-full mt-2 text-xs"
                    @click="jumpToWithdraw('share_wallet','邀请奖',userWallet.share_wallet)"> 提现
            </button>
          </div>
        </div>

        <img src="../../assets/img/profile/chouj.png" class="w-full px-4 my-2"
             @click="jumpToPage('award')"/>
        <img src="../../assets/img/profile/hjyj.png" class="w-full px-4" style="margin-bottom: 20px"
             @click="jumpToPage('withdrawGold')"/>

        <h3 class="text-xl font-bold  ml-4">常用功能</h3>
        <div class="bg-white text-black rounded-lg m-4">
          <div class="grid grid-cols-4 gap-4 text-center  ">
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item"
                 @click="jumpToPage('realNameAuth')">
              <img src="../../assets/img/profile/ic-tools-01.png" style="height: 40px">
              <div class="mt-2 text-sm">实名绑定</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item"
                 @click="jumpToPage('bindBankCard')">
              <img src="../../assets/img/profile/ic-tools-02.png" style="height: 40px">
              <div class="mt-2 text-sm">银行卡绑定</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item" @click="jumpToPage('contactUs')">
              <img src="../../assets/img/profile/ic-tools-03.png" style="height: 40px">
              <div class="mt-2 text-sm">官网群聊</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item" @click="jumpToPage('team')">
              <img src="../../assets/img/profile/ic-tools-04.png" style="height: 40px">
              <div class="mt-2 text-sm">我的团队</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item"
                 @click="jumpToPage('customService')">
              <img src="../../assets/img/profile/ic-tools-05.png" style="height: 40px">
              <div class="mt-2 text-sm">在线客服</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item"
                 @click="jumpToPage('fundDetails')">
              <img src="../../assets/img/profile/ic-tools-06.png" style="height: 40px">
              <div class="mt-2 text-sm">资金明细</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item"
                 @click="jumpToPage('withdrawRecord')">
              <img src="../../assets/img/profile/ic-tools-07.png" style="height: 40px">
              <div class="mt-2 text-sm">提现记录</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 pt-4 item"
                 @click="jumpToPage('buyBonusList')">
              <img src="../../assets/img/profile/ic-tools-08.png" style="height: 40px">
              <div class="mt-2 text-sm">购买记录</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 py-4 item"
                 @click="jumpToPage('withdrawGold')">
              <img src="../../assets/img/profile/ic-tools-09.png" style="height: 40px">
              <div class="mt-2 text-sm">黄金邮寄</div>
            </div>
            <div class="flex flex-col items-center bg-white rounded-lg px-2 py-4 item" @click="toDownloadApp">
              <img src="../../assets/img/profile/ic-tools-10.png" style="height: 40px">
              <div class="mt-2 text-sm">app下载</div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 my-4 ">
          <button class="bg-blue-700 text-white w-full py-2 rounded-full" @click="onSignOut"> 退出登录</button>
        </div>

      </div>
    </div>
    <popup v-model="show" class="w-11/12 max-w-md rounded-lg" position="center" closeable>
      <div class="bg-white p-4 rounded-lg">
        <div class="flex justify-center items-center mb-4">
          <h2 class="text-xxl font-bold">股权转让书</h2>
        </div>

        <div class="text-base">
          <p class="mb-2">您的股权由世界500强公司回收，腾讯集团，阿里巴巴，京东，小米集团，沃尔玛，共同回收股权。</p>
          <p class="mb-2 font-bold">本人愿意出售股权</p>
        </div>

        <div class="mt-4">
          <field v-model="idCardNo" label="身份证号码:" label-width="76"/>
          <field v-model="signer" label="协议签署人:" labelWidth="76"/>
        </div>

        <div class="mt-4 text-center">
          <Button type="danger" block @click="agree">同意签署</Button>
        </div>
      </div>

      <!--      <div class="absolute inset-x-0 bottom-0 h-16 bg-contain bg-no-repeat bg-bottom" style="background-image: url('/path/to/your/background-image.png');"></div>-->
    </popup>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {WithdrawalType} from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import bindBankCard from "@/pages/profile/bindBankCard.vue";
import {Field, Icon, Popup, Button} from "vant";

export default {
  name: "index",
  components: {Popup, Field, Button},
  computed: {
    ...mapState("user", ["userInfo", "identifyInfo", "userWallet", "bankCardInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      console.log(this.identifyInfo);
      if (this.identifyInfo && this.identifyInfo.name) {
        return this.identifyInfo.name;
      } else {
        return "未设置";
      }
    },
  },
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      WithdrawalType,
      accountStatistic: {},
      shareData: {},
      url: '',
      show: false,
      idCardNo: '',
      signer: '',
    };
  },

  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getIdentifyInfo();
    this.getUserWallet();
    this.getShareData();
  },
  watch: {
    userInfo(newVal) {
      if (newVal) {
        this.url = `${this.$config.WEB_URL}/register?code=${newVal.code}`;
      }
    },
    identifyInfo(newVal) {
      if (newVal) {
        this.idCardNo = newVal.id_card_no;
        this.signer = newVal.name;
      }
    },
  },
  filters: {
    toIntegerOrZero(value) {
      if (value !== undefined) {
        return Math.floor(value);
      } else {
        return 0;
      }
    },
    formatDecimal(value) {
      if (isNullOrEmpty(value))
        return '0.00';
      return parseFloat(value).toFixed(0);
    },
    formatDecimal2(value) {
      if (isNullOrEmpty(value))
        return '0.00';
      return parseFloat(value).toFixed(2);
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },
  methods: {
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo",
      "getUserWallet", "getBankCardInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    getShareData() {
      axios
          .get(api.shareData)
          .then((res) => {
            this.shareData = res.data.data
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    onSignIn() {
      console.log('onSignIn', this.identifyInfo)
      // if (isNullOrEmpty(this.identifyInfo) || this.identifyInfo.status != 2) {
      //   this.$dialog.confirm({
      //     title: '提示',
      //     message: '签到需要实名认证，是否前往实名认证？',
      //   })
      //       .then(() => {
      //         this.$router.push({name: 'realNameAuth'});
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }

      axios
          .post(api.signIn)
          .then((res) => {
            this.$toast.success('签到成功!');
            this.getUserInfo();
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToWithdraw(type, title, amount) {
      // if (type == 'income_wallet') {
      //     this.$dialog.alert({
      //       title: '提示',
      //       message: '周六周日银行放假',
      //     })
      //         .then(() => {
      //           // this.$bus.$emit('active', 1);
      //           // this.$router.push({path: '/invite-new2'});
      //         })
      //         .catch(() => {
      //         });
      //     return
      // }
      if (type == 'share_wallet') {
        this.$router.push({
          name: "withdrawYqj",
          query: {type, title, amount},
        });
        return
      }
      if (type == 'income_wallet') {
        this.$router.push({
          name: "withdrawGqfh",
          query: {type, title, amount},
        });
        return
      }
      if (type == 'stock') {
        if (this.userInfo.is_stock_transfer == 0) {
          this.show = true;
          return
        }
        this.$router.push({
          name: "withdrawGq",
          query: {type, title, amount},
        });
        return;
      }
      // if (type == WithdrawalType.GZ && amount <= 100) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '满100可提',
      //   })
      //       .then(() => {
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }
      // if ((type == WithdrawalType.REFERRAL||type == WithdrawalType.CASH)&&this.userInfo.rjmoney<=0) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '先购买生活保障！',
      //   }).then(()=>{
      //     this.$router.push({path: '/livingsecurity'});
      //   })
      //   return
      // }
      // if (isNullOrEmpty(this.identifyInfo) || this.identifyInfo.status !== 2) {
      //   this.$dialog.confirm({
      //     title: '提示',
      //     message: '提现需要实名认证，是否前往实名认证？',
      //   })
      //       .then(() => {
      //         this.$router.push({name: 'realNameAuth'});
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }
      if ((type == 'invite_award' || type == 'recommend_award' || type == 'fuping_wallet') && this.userWallet.isBuyStock == 0) {
        this.$dialog.alert({
          title: '提示',
          message: '先购买一带一路股权',
        }).then(() => {
          this.$bus.$emit('active', 2);
          this.$router.push({path: '/livingsecurity'});
        })
        return;
      }
      // if (type == 'stock') {
      //   this.$router.push({
      //     name: "withdrawStock",
      //     query: {type: 'income_wallet', title, amount},
      //   });
      //   // this.$router.push({name: 'fundDetails', query: {type: 'stock'}})
      //   return;
      // }
      this.$router.push({
        name: "withdrawal",
        query: {type, title, amount},
      });
    },
    close() {
      this.show = false;
    },
    async agree() {

      if (this.signer.trim() == '') {
        this.$toast('请填写签署人');
        return;
      }
      if (this.idCardNo.trim() == '') {
        this.$toast('请填写身份证号码');
        return;
      }
      try {
        await axios.post(api.signStock, {
          id_card_no: this.idCardNo,
          name: this.signer,
        })
        this.$router.push({
          name: "withdrawGq",
          query: {type: 'stock', title: '我的股权', amount: this.userWallet.stock},
        });
        this.getUserInfo();
      } catch (err) {
        this.$toast(err.msg || '签署失败');
      } finally {
        this.close();
      }
    },
    toDownloadApp() {
      window.location.href = '/download.html';
    },
    jumpToPage(name) {
      if (name == bindBankCard && this.bankCardInfo.status == 2) {
        this.$router.push({name: 'getBankCard'});
        return
      }
      if (name == 'socialSecurity' && this.userInfo.tjnum < 1) {
        this.$dialog.confirm({
          title: '提示',
          message: '请先邀请一位用户实名注册,是否立即去邀请？',
        })
            .then(() => {
              this.$router.push({name: 'inviteUser'});
            })
            .catch(() => {
            });
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      if (name == 'withdrawGold') {
        this.$router.push({
          name: "withdrawGold",
          query: {
            type: 'gold', amount: this.userWallet.gold
          },
        });
        return;
      }
      if (name == 'award') {
        this.$bus.$emit('active', 2);
      }
      this.$router.push({name});
    },
    onSignOut() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要退出登录吗？',
      })
          .then(() => {
            this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
          })
          .catch(() => {
          });
    },
    getAccountStatistics() {
      axios.get(api.accountStatistic)
          .then((res) => {
            this.accountStatistic = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    showStockValue() {
      this.$dialog.alert({
        title: '提示',
        message: `当前市值：${this.accountStatistic.stockPrice}元`,
      });
    },
  },
};
</script>

<style scoped lang="less">


.user_info {
  display: flex;
  background-size: 100% auto;
  background-repeat: no-repeat;
  justify-content: space-between;
  padding: 10px 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

.header a img {
  width: 38px;
  height: 38px;
}

.base_info p {
  color: #FFFFFFD9;
  font-size: 14px;
  margin-left: 10px;

}


.invite-box {
  background: #FDE0B4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 60px;
  flex-direction: column;
  justify-content: center;
}

.invite-box p {
  color: #3E3E3E;
  font-size: 11px;
  margin-top: 3px;
}


.text-value {
  font-family: DINCond-Bold;
  font-size: 17px;
  color: #3E3E3E;
  letter-spacing: 0;
  font-weight: 400;
}

.text-label {
  font-family: PingFangSC-Light;
  font-size: 12px;
  color: #3E3E3E;
  letter-spacing: 0;
  font-weight: 200;
}

.h-line {
  background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.50) 50%, rgba(255, 255, 255, 0.00) 100%);
  height: 1px;
  width: 100%;
}

.v-line {
  background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.50) 50%, rgba(255, 255, 255, 0.00) 100%);
  width: 1px;
  height: 100%;
}

.tools-list {
  display: flex;
  flex-wrap: wrap;
}

.item-btn {
  width: 90%;
  background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #AB0F13;
  margin: 20px auto auto;
}

.item {
  font-size: 14px;
  justify-content: center;

  img {
    height: 24px;
  }
}
</style>
