<template>
  <div class="menu_box bg-white flex flex-row justify-center py-3 mt-2 mx-2 ">

    <div class="  flex-1  w-80 py-2 flex flex-col justify-center items-center text-base"
         @click="jumpToPage('centerFile')">
      <img src="@/assets/img/index/ic_file.png" style="width: 30px;margin-bottom: 5px">
      中央文件
    </div>
    <div class="  flex-1   w-80 py-2 flex flex-col justify-center items-center text-base"
         @click="jumpToPage('contactUs')">
      <img src="@/assets/img/index/ic_group_chat.png" style="width: 30px;margin-bottom: 5px">
      官网群聊
    </div>
    <div class="   flex-1  w-80 py-2 flex flex-col justify-center items-center text-base"
         @click="jumpToPage('customService')">
      <img src="@/assets/img/index/ic_custom_service.png" style="width: 30px;margin-bottom: 5px">
      在线客服
    </div>
    <div class="  flex-1   w-80 py-2 flex flex-col justify-center items-center text-base"
         @click="jumpToPage('downloadApp')">
      <img src="@/assets/img/index/ic_download.png" style="width: 30px;margin-bottom: 5px">
      APP下载
    </div>
    <popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <cell title="客服VIP线路1" is-link
            url="https://www.zxkeful.com/index/index?code=49VkzQsLnHG8ELYdy3FlBxSEMAsLpcZ5jKi4MYA3XOG1NLxUpNmNLWcmz12qHQoQlj2s4iXw%2FzjgvtmomDR0VshLNZ5BAxBLxRoBsOnah8%2FS7vKKZuG9lF1RoudmuJT4Wg"/>
      <cell title="客服VIP线路2" is-link
            url="http://kefu.suodeshuijiesuan.net/im.htm?pid=2c9180918f9253f0018fa045a50802a3"/>
    </popup>
  </div>

</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import {Popup, Cell} from "vant";

export default {
  components: {
    Popup, Cell
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = '/download.html';
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL || "https://www.suodeshuivip.com/index/index?code=5NUyz1pdySC8ELYdy3FkUkSNN1sLpcZ5jKi4MYA3XOG1NLxUpNmNLWcmz12qHQoQlj2s4iXw%2FzjgvtmomDR0VshLNZlBAxBLxRoBsOnZh8%2FS7vKKZuG9lF1RoudmuJT4Xw";
        return
      }
      this.$router.push({name});
    },
    onSignIn(e) {
      // 2未签到 1已签到
      if (e == 1) {
        this.$toast("今日已签到完成！");
        return false;
      }
      axios
          .get(api.signIn)
          .then((res) => {
            this.$toast.success('签到成功!');
            this.getUserInfo();
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>
<style scoped lang="less">
.menu_box {
  border-radius: 12px;
}
</style>
