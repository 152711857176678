<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="text-sm text-red-500  mx-2 my-4">为了您的资金安全，请认真核实银行卡号是否正确，否则资金无法正常到账。</div>
        <form  class="rounded-3xl bg-white p-2 mx-4 font-bold " style="height: auto;">
          <field
              v-model="realname"
              name="realname"
              label="真实姓名"
              placeholder="请输入真实姓名"
              :center="true"
              input-align="right"
          />
          <field
              v-model="bankname"
              name="bankname"
              label="银行名称"
              placeholder="请输入银行名称"
              :center="true"
              input-align="right"
          />
          <field
              v-model="bankcode"
              type="number"
              name="bankcode"
              label="银行卡号"
              placeholder="请输入银行卡号"
              :center="true"
              input-align="right"
          />
<!--          <field-->
<!--              v-model="bank_address"-->
<!--              name="bank_address"-->
<!--              label="支行"-->
<!--              placeholder="请输入开户支行"-->
<!--              :center="true"-->
<!--              input-align="right"-->
<!--              :readonly="isBind"-->
<!--          />-->

        </form>
        <div class="mt-8 mb-2 mx-8">
        <Button round block type="info" color="#E60012" @click="onSubmit">确认</Button>
      </div>
<!--        <div class="mt-8 mb-2 mx-2 text-center text-red-500" v-if="bankCardInfo && bankCardInfo.status && bankCardInfo.status == 1">-->
<!--          审核中-->
<!--        </div>-->
      </div>

    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, Field, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, Button, Field},
  computed: {
    ...mapState("user", ["bankCardInfo", "identifyInfo"]),
  },
  watch: {
    // identifyInfo(newVal, oldVal) {
    //   if (newVal) {
    //     this.realname = newVal.name;
    //   }
    // },
    bankCardInfo(newVal, oldVal) {
      if (newVal) {
        this.isBind = (newVal.status == 1);
        this.bankname = newVal.bank_name;
        this.bankcode = newVal.bank_no;
        this.bank_address = newVal.bank_address;
        this.realname = newVal.real_name;
      }
    },
  },
  mounted() {
    this.getIdentifyInfo();
    this.getBankCardInfo();
  },
  data() {
    return {
      title: this.$route.meta.title,
      WithdrawalType,
      bankname: "",
      realname: "",
      bankcode: "",
      bank_address: "",
      isBind: false,
    }
  },
  methods: {
    ...mapActions("user", ["getBankCardInfo", "getIdentifyInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(event) {
      event.preventDefault();
      const {bankname, realname, bankcode, bank_address} = this;
      if (isNullOrEmpty(realname)) {
        this.$toast("请输入真实姓名");
        return
      }
      const regName = /^[\u4e00-\u9fa5]+·[\u4e00-\u9fa5]+$/;
      const regName1 = /^([\u4E00-\u9FA5])*$/;
      if (!regName.test(this.realname) && !regName1.test(this.realname)) {
        this.$toast('真实姓名填写有误!')
        return;

      }

      if (isNullOrEmpty(bankname)) {
        this.$toast("请输入银行名称");
        return
      }
      const regBankName = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!regBankName.test(this.bankname)) {
        this.$toast('银行名称填写有误!')
        return;
      }
      if (isNullOrEmpty(bankcode)) {
        this.$toast("请输入银行卡号");
        return
      }
      // if (isNullOrEmpty(bank_address)){
      //   this.$toast("请输入开户支行");
      //   return
      // }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      axios.post(api.bindBankCard, {
        bank_name: bankname,
        real_name: realname,
        bank_no: bankcode,
      }).then((res) => {
        this.$toast.clear();
        this.$toast("银行卡绑定成功");
        this.getBankCardInfo();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        err.msg && this.$toast(err.msg);
      });
    },
  },

}
</script>

<style scoped lang="less">
.page-container /deep/ .van-field__control--right  {
  text-align: left !important;
}
.page-container /deep/ .van-field__control {
  width: auto !important;
}
.page-container /deep/ .van-field__label {
  color: black;
}
.page-container /deep/ .van-cell {
  line-height: 40px;
}


</style>
