<template>
  <div class="w-screen h-screen bg-windBg">
    <router-view />
  </div>
</template>

<script>
import UserStorage from "@/utils/user_storage";

export default {
  name: "App",
  components: {},
  mounted() {
    console.log("App mounted");
    const token = UserStorage.getToken()
    if (token) {

      UserStorage.setToken(token);
      window.token = token;
    }
  },
  methods: {
  }
};
</script>

<style lang="less">
body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content {
  max-height: calc(100vh - 5.95rem);
  overflow-y: auto;
}
.page-content-2 {
  max-height: calc(100vh - 2.8rem);
  overflow-y: auto;
}
</style>
