<template>
  <div class="page_record h-full w-full flex flex-col bg-gray-100">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        class="bg-transparent"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full flex-1">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div class="space-y-4 px-2">
              <div v-for="(item, index) in list" :key="index" class="bg-white rounded-lg p-4" @click="jumpToDetail(item)">
                <div class="flex justify-between items-start mb-2">
                  <span class="text-sm text-gray-600">订单号: {{ item.no }}</span>
                  <span :class="getStatusClass(item.status)">{{ getStatusStr(item.status)}}</span>
                </div>
                <div class="flex items-center">
                  <img src="@/assets/img/profile/gold-img.png" alt="Gold bars" class="w-16 h-16 object-cover rounded-md mr-4"/>
                  <div class="flex-1">
                    <h2 class="font-bold text-lg">黄金邮寄</h2>
                    <p class="text-red-500 font-bold">重量: {{ item.num }}g</p>
                    <p class="text-gray-600">运费: 免费</p>
                  </div>
                  <button class="mt-2 px-4 py-2 border border-green-500 text-green-500 rounded-full text-sm" @click="jumpToDetail(item)">
                    查看物流
                  </button>
                </div>

              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import {Empty, List, NavBar, PullRefresh,} from "vant";
import {isNullOrEmpty} from "@/utils";

export default {
  name: "index",
  components: { NavBar, List, Empty, PullRefresh},
  data() {
    return {
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      list: [

      ],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
      addressInfo:this.$route.query
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return "购买记录";
    }
  },
  filters: {
    formatDecimal(value) {
      if(isNullOrEmpty(value))
        return '30.00';
      return parseFloat(value).toFixed(2);
    },
  },
  mounted() {
    this.getUserInfo();
    this.loadMore();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    jumpToDetail(item){
      console.log('jumpToDetail',this.addressInfo,item)
      this.$router.push({
        name:'mailProgress',
        query: {
          ...this.addressInfo,
          ...item
        }
      });
    },
    refreshData() {
      this.refreshing = true
      this.pageNo = 1
      this.totalPage = 0
      this.loadMore()
    },
    loadMore() {
      // this.loading = false
      // this.refreshing = false
      // this.finished = true;
      // return
      axios
          .get(api.withdrawalRecordList, {params:{coin_type:'gold'}})
          .then((res) => {
            this.list = res.data.list;
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },

    getStatusClass(status) {
      switch (status) {
        case 0:
          return 'text-blue-500';
        case 2:
          return 'text-blue-500';
        case 1:
          return 'text-blue-500';
        case 3:
          return 'text-red-500';
        default:
          return 'text-gray-500';
      }
    },
    getStatusStr(status) {
      switch (status) {
        case 0:
          return '待审核';
        case 1:
          return '发货中';
        case 2:
          return '已完成';
        case 3:
          return '审核失败';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.page_record {
  background-color: #f5f5f5;
}
.mb-2{
  line-height: 24px;
}
</style>
