<template>
  <div class="page-container h-screen  text-black">
    <nav-bar
        :title="data.title.concat('提现')"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent text-white "
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full ">
      <div class="overflow-y-auto h-full">
        <div class="text-center mt-4">
          <p class=" text-sm py-2 rounded w-auto " style="background: #DCDCDC;color: #CF0010;">提现如果遇到高峰期，可能会延迟到账</p>
          <h1 class=" font-bold mt-4" style=" color: #CF0010;font-size:40px">{{ balance }}</h1>
          <p style="font-size: 20px;margin-bottom: 20px">总金额(元)</p>
        </div>

        <form @submit.prevent="onSubmit" class="bg-white rounded-t-lg p-4 mt-6 w-full">
          <h2 class="text-black text-lg font-bold mb-4">申请提现</h2>
          <!--          <div class="grid grid-cols-3 gap-4 mb-4">-->
          <!--            <button-->
          <!--                v-for="amt in [300, 500, 1000, 2000, 3000, 5000, 10000, 20000, 30000]"-->
          <!--                :key="amt"-->
          <!--                type="button"-->
          <!--                @click="amount = amt.toString()"-->
          <!--                style="height: 70px "-->
          <!--                :class="['py-2 rounded-lg border', amount === amt.toString() ? 'bg-red-500 text-white' : 'bg-white text-black']"-->
          <!--            >-->
          <!--              {{ amt }}元-->
          <!--            </button>-->
          <!--          </div>-->
          <div class="item-box">
            <div class="item">

              <span>￥</span>
              <input
                  type="text"
                  placeholder="请输入提现金额"
                  maxlength="11"
                  v-model="amount"
              />
            </div>
          </div>

          <Button :disabled="disabled" type="primary" round block color="#CF0010" native-type="submit" class="w-full "
                  style="margin-bottom: 36px;font-size: 18px">
            {{ disabled ? `${formatCountdown}可提现` : '立即提现' }}
          </Button>

          <!--          <div class="mt-4 text-center " style="margin-bottom: 24px">-->
          <!--          <a href="#" class="text-black " style="text-decoration: underline;font-size: 18px" >收益明细</a>-->
          <!--          </div>-->

        </form>
        <!--        <div class=" text-center text-black bg-white " style="height: 80px;border-top: rgba(128,128,128,0.35) solid 1px;padding: 24px 0;">-->
        <!--          <p class="">累计收益: <span class="text-red-600">2.50元</span></p>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import {isNullOrEmpty} from "@/utils";


export default {
  components: {NavBar, Button,},
  computed: {
    ...mapState("user", ["userInfo"]),
    disabled() {
      return this.countdown.seconds > 0 || this.countdown.minutes > 0 || this.countdown.hours > 0 || this.countdown.days > 0
    },
    formatCountdown() {
      let countdownStr = ''
      if (this.countdown.days > 0) {
        countdownStr = `${this.countdown.days}天${this.countdown.hours}时${this.countdown.minutes}分${this.countdown.seconds}秒`
      } else {
        if (this.countdown.hours > 0) {
          countdownStr = `${this.countdown.hours}时${this.countdown.minutes}分${this.countdown.seconds}秒`
        } else {
          if (this.countdown.minutes > 0) {
            countdownStr = `${this.countdown.minutes}分${this.countdown.seconds}秒`
          } else {
            countdownStr = `${this.countdown.seconds}秒`
          }
        }
      }
      return countdownStr
    }
  },
  data() {
    return {
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
      targetDate: new Date('2024-08-18T00:00:00'),
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000); // 每秒更新一次倒计时
  },

  methods: {
    ...mapActions("user", ["getUserWallet"]),
    updateCountdown() {
      const currentDate = new Date();
      const timeDiff = this.targetDate.getTime() - currentDate.getTime();
      this.countdown.seconds = Math.floor(timeDiff / 1000) % 60;
      this.countdown.minutes = Math.floor(timeDiff / 1000 / 60) % 60;
      this.countdown.hours = Math.floor(timeDiff / 1000 / 60 / 60) % 24;
      this.countdown.days = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
      // console.log('倒计时', this.countdown)
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.go(-1);
    },
    onSubmit(event) {
      event.preventDefault();
      const {amount} = this;

      // console.log('申请提现',amount,this.balance)
      // if (parseFloat(amount) > parseFloat(this.balance)) {
      //   // this.$toast("提现金额不能大于可用余额");
      //   this.$toast("每天提现一次");
      //   return;
      // }
      if (this.data.type == 'income_wallet') {
        this.$dialog.alert({
          title: '提示',
          message: '先提现邀请奖',
        })
            .then(() => {
              this.onClickLeft();
            })
            .catch(() => {
            });
        return
      }
      if (this.data.type === 'share_wallet') {
        this.$dialog.alert({
          title: '提示',
          message: '周六日银行放假',
        })
            .then(() => {
              this.$bus.$emit('active', 1);
              this.$router.push({path: '/invite-new2'});
            })
            .catch(() => {
            });
        return
      }
      if (isNullOrEmpty(amount)) {
        this.$toast("请输入提现数额");
        return
      }
      axios.post(api.userWithdrawal, {
        num: amount,
        coin_type: this.data.type,
      }).then((res) => {
        this.$toast("申请成功，正在审核");
        this.getUserWallet();
        this.onClickLeft();
      }).catch((err) => {
        this.$dialog.alert({
          title: '提示',
          message: err.msg || '提现失败',
        })
            .then(() => {
              // this.$bus.$emit('active', 1);
              // this.$router.push({path: '/invite-new2'});
            })
            .catch(() => {
            });
      });
    },

  },


}
</script>

<style scoped lang="less">
.page-container {
  background-image: url("../../assets/img/index/bg_top3.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

}
.item-box {
  margin-top: 40px;
  margin-bottom: 36px;

  .item {
    height: 55px;
    margin-top: 22px;
    display: flex;
    border: grey solid 1px;
    border-radius: 10px;
    align-items: center;
    padding: 0px 22px;

    span {
      width: 8%;
      background: none;
      font-size: 30px;
      font-weight: bold;
    }


    input {
      width: 97%;
      padding-left: 3%;
      height: 100%;
      background: none;
      font-size: 20px;
      font-weight: bold;
      color: #333;

    }

    input::placeholder {
      color: rgba(128, 128, 128, 0.5);
    }
  }
}


</style>
