<template>
  <div class=" page_fund_details h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        :border="false"
        class="bg-transparent"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <div class="px-2 py-2 ">
          <tabs v-model="activeTab" @click="onClick" :line-width="20" line-height="3px">
            <tab title="扶持金" name="invite_award">
            </tab>
            <tab title="推荐奖" name="recommend_award">
            </tab>
            <tab title="扶贫金" name="fuping_wallet">
            </tab>
            <tab title="股权" name="stock">
            </tab>
            <tab title="股权分红" name="income_wallet">
            </tab>
          </tabs>
        </div>
        <PullRefresh v-model="refreshing" @refresh="refreshData">
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div v-for="(group, date) in groupedList" :key="date" class="mb-4 mx-2">
              <div class="bg-gray-200 px-4 py-2 rounded-t-lg">{{ date }}</div>
              <div v-for="(item, index) in group" :key="index" class="bg-white px-4 py-3 border-b">
                <div class="flex justify-between items-center">
                  <div>
                    <div class="text-base font-semibold">{{ item.content }}</div>
                    <div class="text-sm text-gray-500">余额 {{ item.after }}</div>
                  </div>
                  <div class="text-red-500 font-semibold">{{activeTab=='stock'?'':'现金'}}{{ item.total|formatDecimal }}{{activeTab=='stock'?'股':'元'}}</div>
                </div>
              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, NavBar, PullRefresh, Tab, Tabs} from "vant";
import {EnumOperations, FundType, getFundTypeLabel} from "@/config";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, List, Empty, PullRefresh,   Tab, Tabs},
  data() {
    return {
      getFundTypeLabel,
      FundType,
      title: this.$route.meta.title,
      size: 20,
      page: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      coin_type: '',
      activeTab: this.$route.query.type || 'invite_award',
    };
  },
  computed: {
    groupedList() {
      const grouped = {};
      this.list.forEach(item => {
        const date = this.$options.filters.timeFormat(item.created_at, 'YYYY-MM-DD');
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(item);
      });
      return grouped;
    }
  },
  filters: {
    getTypes(value) {
      if (value == EnumOperations.INCREASE) {
        return "+"
      } else {
        return "-"
      }
    },

    formatDecimal(value) {
      if (isNullOrEmpty(value))
        return '0.00';
      return parseFloat(value).toFixed(0);
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClick(name) {
      this.loading = true
      this.finished = false
      this.activeTab = name;
      this.page = 1;
      this.loadMore();
    },
    refreshData() {
      this.finished = false
      this.refreshing = true;
      this.page = 1;
      this.loadMore();
    },
    loadMore() {
      axios.get(api.balanceLog, {
        params: {
          coin_type: this.activeTab,
          page: this.page,
          size: this.size,
        }
      }).then((res) => {
        if (this.page === 1) {
          this.list = res.data.list;
          this.count = res.data.total;
        } else {
          this.list = this.list.concat(res.data.list);
        }
        this.loading = false;
        this.refreshing = false;
        if (this.list.length < this.count) {
          this.page++;
        } else {
          this.finished = true;
        }
      }).catch((err) => {
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
        err.msg && this.$toast(err.msg);
      });
    },
  },
};
</script>

<style scoped>
.page_fund_details /deep/ .van-tabs__nav {
  background: transparent;
}

.van-tabs__line {
  background-color: #FF5848;
}

.tab-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.tab-title.active {
  color: #FF5848;
}
</style>
