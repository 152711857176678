<template>
  <div class="page-extra h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <van-tabs v-model="activeTab" :line-width="20" line-height="3px" @click="onClick">
          <van-tab title="扶持金" name="invite_award">
          </van-tab>
          <van-tab title="推荐奖" name="recommend_award">
          </van-tab>
          <van-tab title="扶贫金" name="fuping_wallet">
          </van-tab>
          <van-tab title="股权分红" name="income_wallet">
          </van-tab>
          <van-tab title="邀请奖" name="share_wallet">
          </van-tab>
          <van-tab title="股权" name="stock">
          </van-tab>
        </van-tabs>
        <PullRefresh v-model="refreshing" @refresh="refreshData">
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div v-for="(item, index) in list" :key="index" class="bg-white rounded-lg p-4 mb-3 mx-4">
              <div class="flex justify-between items-center mb-2" v-if="activeTab=='stock'">
                <span class="text-lg font-bold">出售股权</span>
                <span :class="getStatusColorClass(item.status)">{{ getReviewStatusLabel(activeTab, item) }}</span>
              </div>
              <div class="flex justify-between items-center mb-2" v-else>
                <span class="text-lg font-bold">提现到【{{item.bank_name}}】</span>
                <span :class="getStatusColorClass(item.status)">{{ getReviewStatusLabel(activeTab, item) }}</span>
              </div>
              <div class="flex items-center mb-2" v-if="activeTab=='stock'">
                <span class="text-black text-lg font-bold mr-1">数量：</span>
                <span class="text-red-500 text-lg font-bold">{{ item.num | formatDecimal }}股</span>
              </div>
              <div class="flex items-center mb-2" v-else>
                <span class="text-red-500 text-lg font-bold mr-1">金额：</span>
                <span class="text-red-500 text-lg font-bold">¥ {{ item.num | formatDecimal }}</span>
              </div>
              <div class="text-gray-500">
                {{ item.created_at | timeFormat('YYYY/MM/DD HH:mm') }}
              </div>
              <div v-if="item.status==2&&(coin_type == 'invite_award' || coin_type == 'recommend_award'|| coin_type == 'fuping_wallet')" class="text-red-500 mt-2">
                注：已成功提现、国家财政局每月10号自动打款到银行卡
              </div>
              <div v-if="item.status==2&&coin_type == 'share_wallet'" class="text-red-500 mt-2">
                注：3个工作日自动到账银行卡
              </div>
              <div v-if="item.status==2&&coin_type == 'income_wallet'" class="text-red-500 mt-2">
                注：24小时内到账
              </div>
              <div v-if="item.status==3&&(coin_type == 'share_wallet'||coin_type == 'income_wallet')" class="text-red-500 mt-2">
                注：银行风控打款失败
              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, NavBar, PullRefresh, Tab, Tabs} from "vant";
import {getReviewStatusLabel, ReviewStatus, getNTypeLabel, NType} from "@/config";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, List, Empty, PullRefresh, [Tab.name]: Tab, [Tabs.name]: Tabs},
  filters: {
    formatDecimal(value) {
      if (isNullOrEmpty(value))
        return '0';
      return parseFloat(value).toFixed(0);
    },
  },
  data() {
    return {
      getReviewStatusLabel,
      NType,
      ReviewStatus,
      title: this.$route.meta.title,
      count: 0,
      size: 20,
      page: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      activeTab: '0',
      coin_type: 'invite_award',
    };
  },
  methods: {
    onClick(name) {
      this.loading = true
      this.finished = false
      this.coin_type = name;
      this.page = 1;
      this.count = 0;
      this.loadMore();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    refreshData() {
      this.finished = false
      this.refreshing = true;
      this.pageNo = 1;
      this.count = 0;
      this.loadMore();
    },
    loadMore() {
      axios.get(api.withdrawalRecordList, {

        params: {
          page: this.page,
          size: this.size,
          coin_type: this.coin_type,
        }
      }).then((res) => {
        if (this.page === 1) {
          this.list = res.data.list;
          this.count = res.data.total;
        } else {
          this.list = this.list.concat(res.data.list);
        }
        this.loading = false;
        this.refreshing = false;
        if (this.list.length < this.count) {
          this.page++;
        } else {
          this.finished = true;
        }
      }).catch((err) => {
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
        err.msg && this.$toast(err.msg);
      });
    },
    getStatusColorClass(status) {
      switch (status) {
        case ReviewStatus.PAID:
          return 'text-green-500';
        case ReviewStatus.PENDING:
          return 'text-blue-500';
        case ReviewStatus.PAYMENT_FAILED:
          return 'text-red-500';
        default:
          return 'text-gray-500';
      }
    },
  },
};
</script>

<style scoped>
.page-extra /deep/ .van-tabs__nav {
  background: transparent;
}

.page-extra /deep/ .van-nav-bar {
  background: transparent;
}

</style>
