<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="bg-white m-4 p-3" v-html="systemInfo.customer_service_group">

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import TitleBar from "@/components/TitleBar";
import {mapActions, mapState} from "vuex";
import {NavBar,} from "vant";

export default {
  name: "ContactUs",
  components: {TitleBar,NavBar,},
  computed: {
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.getSystemInfo();
  },
  data() {
    return {
      details: {},
      id: this.$route.params.id,
    }
  },
  methods: {
    ...mapActions("systemInfo", ["getSystemInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
  },



}
</script>

<style scoped lang="less">
.content /deep/ a {
  color: #409eff ;
  text-decoration: none;
}
.copyable-text {
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE 10+/Edge */
  user-select: text; /* 标准语法 */
}
</style>
