<template>
  <div class="min-h-screen bg-cover w-full bg-award overflow-x-hidden bg-EA3C3E">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        :border="false"
    />
    <div class="">
    <div class="w-full px-2" style="margin-bottom: 14.8rem">
    </div>
    <div class="px-4 py-6">
      <LuckyGrid
          ref="myLucky"
          height="330px"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
          :defaultStyle="defaultStyle"
      />
      <div class="mt-4 rounded-full text-EE2606 py-2 px-4 text-center remain-button">
        今日剩余抽奖次数{{ remain }}
      </div>
    </div>
    <div class="px-4">
      <!-- My Prizes Section -->
      <MyPrizes/>
      <!-- User List Section -->
      <UserAwardList/>
      <overlay :show="showWinningPopup" class=" flex justify-center items-center ">
        <div class="relative flex justify-center items-center">
          <img src="@/assets/img/lottery/bg_popup.png" style="width: 80%"/>
          <img src="@/assets/img/lottery/ic_close.png"
               @click.stop="closePopup"
               class="absolute top-0 right-3 z-10" style="width:40px"/>
          <div class="absolute flex flex-col top-0 right-0 bottom-0 left-0 items-center " style="padding-top: 50px">
            <div class="mb-8 text-center bg-prize">
              <img :src="currentPrize.image" :alt="currentPrize.name" style="width: 60px;height: 60px">
              <p class="text-EE2606 text-base">{{ currentPrize.name }}</p>
            </div>
            <p class="text-EE2606 font-bold text-center text-lg">恭喜您获得{{ currentPrize.title }}</p>
            <p class="text-915422 text-center text-base">领取需求：邀请实名注册{{ currentPrize.needDayInviteNum }}人</p>
            <p class="text-915422 text-center text-base mb-4">当日邀请:{{ currentPrize.todayInviteCount }}人</p>
            <img src="@/assets/img/lottery/ljyq.png" style="height: 45px" @click.stop="inviteUser"/>
          </div>
        </div>

      </overlay>
    </div>
  </div>
  </div>
</template>

<script>


import {NavBar, Overlay} from "vant";
import {mapActions, mapState} from "vuex";
import api from "@/net/api";
import axios from "@/net/axios";
import MyPrizes from "@/components/MyPrizes";
import {isNullOrEmpty} from "@/utils";
import UserAwardList from "@/components/UserAwardList";

export default {
  name: "index",
  components: {NavBar, MyPrizes,UserAwardList, Overlay},
  data() {
    return {
      remain: 0,
      blocks: [{
        imgs: [{
          src: require('@/assets/img/lottery/bg_wheel.png'),
          width: '100%',
          height: '100%',
        }],
        padding: '22px',
      }],
      prizes: [
        {
          x: 0, y: 0, imgs: [{
            src: require('@/assets/img/lottery/prize1.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '扶持金', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得1万扶持金',
          type: 1,
        },
        {
          x: 1, y: 0, imgs: [{
            src: require('@/assets/img/lottery/prize2.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '扶持金', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得5万扶持金',
          type: 1,
        },
        {
          x: 2, y: 0, imgs: [{
            src: require('@/assets/img/lottery/prize3.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '扶持金', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得10万扶持金',
          type: 1,
        },
        {
          x: 0, y: 1, imgs: [{
            src: require('@/assets/img/lottery/prize4.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '黄金10克手链', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得黄金10克手链',
          type: 2,
        },
        {
          x: 2, y: 1, imgs: [{
            src: require('@/assets/img/lottery/prize5.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '黄金2克戒指', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得黄金2克戒指',
          type: 2,
        },
        {
          x: 0, y: 2, imgs: [{
            src: require('@/assets/img/lottery/prize6.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '黄金88克金元宝', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得黄金88克金元宝',
          type: 2,
        },
        {
          x: 1, y: 2, imgs: [{
            src: require('@/assets/img/lottery/prize7.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '黄金188克金条', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得黄金188克金条',
          type: 2,
        },
        {
          x: 2, y: 2, imgs: [{
            src: require('@/assets/img/lottery/prize8.png'),
            width: '90%',
            height: '90%',
          }],
          background: '#FDF2EE',
          shadow: "0 5 1 #F8D0C3",
          fonts: [{text: '黄金1000克金砖', top: '70%', fontSize: '12px', fontColor: '#C93C1F'}],
          title: '恭喜您获得黄金1000克金砖',
          type: 2,
        },
      ],
      defaultStyle: {
        borderRadius: '6px',
      },
      buttons: [{
        x: 1, y: 1,
        background: '#FFCC00',
        shadow: "0 5 1 #DD8E03",
        fonts: [{text: '立即抽奖', top: '10%', fontSize: '30px', fontColor: '#C93C1F'}],
      }],
      isSpinning: false,
      loading: false,
      finished: false,
      refreshing: false,
      isRunning: false,
      showWinningPopup: false,
      currentPrize: {},

    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },

  mounted() {
    this.getUserInfo();
    this.getDrawNum();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    closePopup() {
      console.log('点击关闭')
      this.showWinningPopup = false
    },
    inviteUser() {
      console.log('点击关闭')
      this.showWinningPopup = false
      this.$router.push({name: 'inviteUser'})
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // if (this.remain <= 0) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '抽奖机会已用完,邀请好友赢取更多奖励哦',
      //     theme: 'round-button',
      //   }).catch(err => {
      //   });
      //   return;
      // }
      if (this.isRunning == true) {
        return;
      }
      this.isRunning = true;
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
      axios
          .post(api.luckNum)
          .then((res) => {
            const {type, num, todayInviteCount, needDayInviteNum} = res.data;
            const index = this.prizes.findIndex(item => item.type === res.data.type)
            let title, image, name;
            if (type == 1) {
              title = `${num}扶持金`
              image = require('@/assets/img/lottery/fpj.png')
              name = '扶持金'
            } else {
              title = `黄金${num}克`
              image = require('@/assets/img/lottery/prize7.png')
              name = '黄金'
            }
            this.currentPrize = {title, todayInviteCount, needDayInviteNum, image, name}
            this.$refs.myLucky.stop(index)
          })
          .catch((err) => {
            this.isRunning = false;
            this.$refs.myLucky.stop(-1)
            err.msg && this.$dialog.alert({
              title: '提示',
              message: err.msg,
              theme: 'round-button',
            }).then(() => {
              this.remain--;
              this.isRunning = false;
              this.getDrawNum();
            });
          });
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log('抽奖结束会触发end回调',prize)
      if(!isNullOrEmpty(prize)){
        this.showWinningPopup = true;
      }
      this.remain--;
      this.isRunning = false;
      this.getDrawNum();
    },
    jumpToPage(item) {
      if (item.id === 1) {
        this.$toast('该奖项已被领取');
        return;
      }
      if (this.userInfo?.tjnum >= item.recommendNum) {
        this.$toast('该奖项已被领取');
        return;
      }
      this.$router.push({name: 'inviteUser'})

    },
    getDrawNum() {
      axios
          .get(api.drawNum)
          .then((res) => {
            console.log('剩余抽奖次数', res)
            this.remain = res.data.remainJoinNum;
            // this.buttons = [{
            //   x: 1, y: 1,
            //   background: 'rgba(0,0,0,0)',
            //   fonts: [{
            //     text: this.remain > 0 ? `还有${this.remain}次抽奖机会` : '抽奖机会已用完',
            //     top: '70%',
            //     fontSize: '10px',
            //     color: '#333'
            //   }],
            //   imgs: [{
            //     src: require('@/assets/img/lottery/prize1.png'),
            //     width: '100%',
            //     height: '100%',
            //     bottom: '10%'
            //   }],
            // }];
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>

<style scoped lang="less">
.bg-award /deep/ .van-nav-bar .van-icon {
  color: #fff;
}

.bg-award /deep/ .van-nav-bar {
  background-color: transparent;
}

.bg-award {
  background-image: url('../../assets/img/lottery/bg_main.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.bg-prize {
  background-image: url('../../assets/img/lottery/bg-award.png');
  background-size: 100px 93px;
  background-repeat: no-repeat;
  height: 93px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remain-button {
  background-image: url('../../assets/img/lottery/bg_remain_button.png');
}

.body-content {
  flex-direction: column;
  padding: 70px 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-animation {
  position: relative;
}

.light-source {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: yellow;
  opacity: 0.5;
  z-index: 1;
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes spin {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

</style>
