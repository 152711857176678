<template>
  <div class="page-container h-screen  text-black">
    <nav-bar
        :title="data.title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent text-white "
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full ">
      <div class="overflow-y-auto h-full">
        <div class="text-center mt-4 ">
          <p class=" text-sm py-2 rounded w-auto " style="background: #DCDCDC;color: #CF0010;">提现如果遇到高峰期，可能会延迟到账</p>
          <h1 class=" font-bold mt-4" style=" color: #CF0010;font-size:40px">{{ balance }}</h1>
          <p style="font-size: 14px;margin-bottom: 20px" class="relative">当前股权数量(股)
          <span class="absolute bottom-0 right-2" @click="jumpToDetail" style="color: #CF0010;font-size: 18px;cursor: pointer">股权明细</span>
          </p>

        </div>

        <form   class="bg-white rounded-t-lg p-4 mt-6 w-full">
          <div class="flex flex-row justify-between items-center mt-4 mb-2">
            <span>股权分红金额:</span>
            <span>{{userWallet.income_wallet}}元</span>
          </div>
<!--          <div class="flex flex-row justify-between items-center  mb-2">-->
<!--            <span>可使用提现:</span>-->
<!--            <span><span style="color: #CF0010">{{userWallet.todayRemain}}</span>元</span>-->
<!--          </div>-->
<!--          <h2 class="text-black text-lg font-bold mb-4">申请提现</h2>-->
<!--          <div class="item-box">-->
<!--            <div class="item">-->
<!--              <span>￥</span>-->
<!--              <input-->
<!--                  type="text"-->
<!--                  placeholder="请输入提现金额"-->
<!--                  maxlength="11"-->
<!--                  v-model="amount"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

          <Button round block type="info" color="#CF0010" @click="onSubmit" class="w-full mt-4"
                  style="margin-bottom: 36px;font-size: 18px">
            查看明细
          </Button>

          <!--          <div class="mt-4 text-center " style="margin-bottom: 24px">-->
          <!--          <a href="#" class="text-black " style="text-decoration: underline;font-size: 18px" >收益明细</a>-->
          <!--          </div>-->

        </form>
        <!--        <div class=" text-center text-black bg-white " style="height: 80px;border-top: rgba(128,128,128,0.35) solid 1px;padding: 24px 0;">-->
        <!--          <p class="">累计收益: <span class="text-red-600">2.50元</span></p>-->
        <!--        </div>-->

      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, NavBar} from "vant";
import {WithdrawalType} from "@/config";


export default {
  components: {NavBar, Button},
  computed: {
    ...mapState("user", ["userInfo", "bankCardInfo","userWallet"]),
  },
  data() {
    return {
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
    }
  },
  mounted() {
    this.getUserWallet();
    this.getBankCardInfo();
  },

  methods: {
    ...mapActions("user", ["getUserWallet", "getBankCardInfo"]),

    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.go(-1);
    },
    jumpToDetail() {
      this.$router.push({name: 'fundDetails', query: {type: 'stock'}})
    },
    onSubmit() {
      // event.preventDefault();
      this.$router.push({name: 'fundDetails', query: {type: 'stock'}})
      // return;
      // if(isNullOrEmpty(this.bankCardInfo)||this.bankCardInfo.status!==1)
      // {
      //   this.$dialog.confirm({
      //     title: '提示',
      //     message: '暂无绑定银行卡收款信息',
      //     confirmButtonText: '立即完善',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.$router.push({name: 'bindBankCard'});
      //   }).catch(() => {
      //
      //   });
      //   return
      // }
      //
      // const {amount} = this;
      // if (isNullOrEmpty(amount)) {
      //   this.$toast("请输入提现数额");
      //   return
      // }
      // console.log('申请提现',amount,this.balance)
      // if (parseFloat(amount) > parseFloat(this.userWallet.todayRemain)) {
      //   this.$toast("提现金额不能大于可用余额");
      //   return;
      // }

      // if (this.data.type === WithdrawalType.GZ) {
      //     this.$dialog.alert({
      //       title: '提示',
      //       message: '周末暂未开放',
      //     })
      //         .then(() => {
      //
      //         })
      //         .catch(() => {
      //         });
      //     return
      // }
      // axios.post(api.userWithdrawal, {
      //   num: amount,
      //   coin_type: this.data.type,
      // }).then((res) => {
      //   this.$toast("申请成功，正在审核");
      //   this.getUserWallet();
      //   this.onClickLeft();
      // }).catch((err) => {
      //   err.msg && this.$toast(err.msg);
      // });
    },

  },


}
</script>

<style scoped lang="less">
.page-container {
  background-image: url("../../assets/img/index/bg_top3.png");
  background-size: 100% auto;
  background-repeat: no-repeat;

}

.item-box {
  margin-top: 40px;
  margin-bottom: 36px;

  .item {
    height: 55px;
    margin-top: 22px;
    display: flex;
    border: grey solid 1px;
    border-radius: 10px;
    align-items: center;
    padding: 0px 22px;

    span {
      width: 8%;
      background: none;
      font-size: 30px;
      font-weight: bold;
    }


    input {
      width: 97%;
      padding-left: 3%;
      height: 100%;
      background: none;
      font-size: 20px;
      font-weight: bold;
      color: #333;

    }

    input::placeholder {
      color: rgba(128, 128, 128, 0.5);
    }
  }
}


</style>
