<template>
  <div v-if="showCountdown" class="text-lg font-bold p-2" style="color:red" >
    <span v-if="days > 0 || hours > 0 || minutes > 0" class="text-black">倒计时：</span>
    <span v-if="days > 0">{{ days }}天</span>
    <span v-if="days > 0 || hours > 0">{{ hours }}:</span>
    <span v-if="days > 0 || hours > 0 || minutes > 0">{{ minutes }}:</span>
    <span >{{ seconds }}</span>
    <span class="text-black">活动结束</span>
  </div>
</template>

<script>

export default {
  props: ['pageKey'],
  data() {
    return {
      targetDate: new Date('2024-08-30T00:00:00'),
      remainingTime: 0,
      showCountdown: true,
      timer: null,
    };
  },
  computed: {
    days() {
      return Math.floor(this.remainingTime / (1000 * 60 * 60 * 24));
    },
    hours() {
      return Math.floor((this.remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    },
    minutes() {
      return Math.floor((this.remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    },
    seconds() {
      return Math.floor((this.remainingTime % (1000 * 60)) / 1000);
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    startCountdown() {
      this.remainingTime = this.targetDate.getTime() - new Date().getTime();
      this.timer = setInterval(() => {
        this.remainingTime -= 1000;
        if (this.remainingTime <= 0) {
          clearInterval(this.timer);
          this.showCountdown = false;
          this.$emit('onFinished', true);
        }
      }, 1000);
      if(this.remainingTime > 0) {
        this.$emit('onFinished', false);
      }
      else {
        this.$emit('onFinished', true);
      }
    },
  },
};
</script>

<style lang="less" scoped>

</style>
